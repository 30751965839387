import classNames from "classnames";
import { useState, useMemo } from "react";

import { FormConfirmVariant, FormVinRegoSearch } from "components/app";
import { modalSearchVinRegoTestID } from "components/app/dataTestIDs";

import { IVehicle } from "types";

import "../styles.scss";

enum STEPS {
  VEHICLE_SEARCH = "SEARCH",
  CONFIRM_VARIANT = "CONFIRM_VARIANT",
}

export interface ModalSearchVinRegoProps {
  inspectionType: string;
  dataTestID?: string;
  onCreatedNew: (contract_id: string) => void;
}

export const ModalSearchVinRego = ({
  inspectionType,
  dataTestID,
  onCreatedNew,
}: ModalSearchVinRegoProps) => {
  const [selectedVehicle, setSelectedVehicle] = useState<IVehicle | null>(null);
  const [step, setStep] = useState<STEPS>(STEPS.VEHICLE_SEARCH);
  const isAlreadyExists = selectedVehicle?.already_exists;

  const modalSearchTitle = useMemo(() => {
    switch (step) {
      case STEPS.VEHICLE_SEARCH:
        return "Search by VIN/REGO";
      case STEPS.CONFIRM_VARIANT:
        return "Is this your vehicle?";
      default:
        return "";
    }
  }, [step]);

  const handleStep = (curStep: string) => {
    if (curStep === STEPS.CONFIRM_VARIANT) {
      setStep(STEPS.VEHICLE_SEARCH);
    }
  };

  return (
    <div
      className={classNames("vin-rego", {
        "w-250": isAlreadyExists,
      })}
      data-testid={dataTestID || modalSearchVinRegoTestID}
    >
      <div className="vin-rego__form">
        {modalSearchTitle && !isAlreadyExists && (
          <h3 className="vin-rego__form__title">{modalSearchTitle}</h3>
        )}
        {step === STEPS.VEHICLE_SEARCH && (
          <FormVinRegoSearch
            isAlreadyExists={isAlreadyExists}
            onNextStep={(vehicle) => {
              if (!vehicle.already_exists) {
                setStep(STEPS.CONFIRM_VARIANT);
              }
              setSelectedVehicle(vehicle);
            }}
            onCancel={() => {
              setSelectedVehicle(null);
            }}
          />
        )}
        {step === STEPS.CONFIRM_VARIANT && (
          <FormConfirmVariant
            searchedVehicle={selectedVehicle!}
            inspectionType={inspectionType}
            onNextStep={onCreatedNew}
            onBack={handleStep}
          />
        )}
      </div>
    </div>
  );
};
