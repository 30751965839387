import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useContext } from "react";
import { toast } from "react-toastify";

import { FieldMotorIntelValuationTestID } from "components/app/dataTestIDs";
import { LinkButton } from "components/Button";
import { SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";

import { DISABLED_STATE_COLOR, StorageKeys, showErrorToast } from "utils";

import { ValuationService } from "api/client";

import "../styles.scss";

export interface FieldMotorIntelValuationProps {
  hubOfferAmount: string;
  requested: boolean | undefined;
  isRequesting: boolean;
  onOpenMPLogModal: () => void;
  onRequestMPValuation?: (payload: { type: string }) => void;
  dataTestID?: string;
  rawAmount?: number;
  contractId?: string;
  isTabView?: boolean;
  vehicleName?: string;
  declined?: boolean;
  isMotorIntelDeclined?: boolean;
  canUserView?: boolean;
  onRefresh?: () => void;
  canRequest?: boolean;
}

const PendingValuation = () => (
  <div className="btn-pending">
    Pending
    <SvgImage name="PendingIcon" className="ml-2" />
  </div>
);

export const FieldMotorIntelValuation = (
  data: FieldMotorIntelValuationProps
) => {
  const {
    hubOfferAmount,
    requested,
    isRequesting,
    onOpenMPLogModal,
    onRequestMPValuation,
    dataTestID,
    rawAmount,
    contractId,
    isTabView,
    declined = false,
    isMotorIntelDeclined = false,
    canUserView = true,
    onRefresh,
    canRequest,
  } = data;

  const { user, userID } = useContext(AuthContext);

  const {
    isRefetching: isRefetchingMotorIntelValuationCertificate,
    refetch: refetchMotorIntelValuationCertificate,
  } = useQuery<string, Error>(
    [StorageKeys.MotorIntelValuationCertificate, contractId, userID],
    async () =>
      await ValuationService.requestMotorIntelValuationCertificate(
        contractId || ""
      ),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: async (data: string) => {
        window.open(data, "_blank", "noreferrer");
      },
      onError: (err: Error) => {
        console.log("err", err);
        showErrorToast("Failed to Open Certificate");
      },
    }
  );

  const {
    mutate: handleRequestMPValuation,
    isLoading: isRequestingMPValuation,
    isSuccess,
  } = useMutation(
    (payload: { type: string }) =>
      ValuationService.requestMPValuation(contractId || "", payload),
    {
      onSuccess: () => {
        toast.success("Valuation Requested", {
          autoClose: 2000,
          theme: "colored",
        });
        onRefresh && onRefresh();
      },
      onError: () => {
        toast.error("Failed to Send", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  return (
    <div
      className={classNames(
        "valuation-field flex-col justify-between",
        {
          "!gap-1 !h-auto": rawAmount,
        },
        {
          "py-3": !isTabView,
        }
      )}
    >
      <div
        className="valuation-field--request justify-between w-full"
        data-testid={dataTestID || FieldMotorIntelValuationTestID}
      >
        <label>
          MotorIntel Valuation
          {canUserView && (
            <span
              className="hover:cursor-pointer ml-2"
              onClick={onOpenMPLogModal}
            >
              <SvgImage name="EyeIcon" width={20} height={14} />
            </span>
          )}
        </label>
        {isMotorIntelDeclined ? (
          <div className="btn-declined">Declined</div>
        ) : isRequesting ||
          isRequestingMPValuation ||
          rawAmount ||
          requested ||
          isSuccess ? (
          rawAmount && canUserView ? (
            <label>{hubOfferAmount}</label>
          ) : (
            <PendingValuation />
          )
        ) : (
          !user?.data.user.is_mp_user && (
            <div
              className={classNames("btn-request", {
                [`!bg-[${DISABLED_STATE_COLOR}] pointer-events-none`]:
                  declined || !canRequest,
              })}
              onClick={() => {
                if (!isRequesting && canRequest) {
                  if (onRequestMPValuation) {
                    onRequestMPValuation({ type: "hub" });
                  } else {
                    handleRequestMPValuation({ type: "hub" });
                  }
                }
              }}
              data-testid={`${
                dataTestID || FieldMotorIntelValuationTestID
              }-request`}
            >
              Request
              <SvgImage name="RequestIcon" className="ml-2" />
            </div>
          )
        )}
      </div>
      {!!rawAmount && !isMotorIntelDeclined && canUserView && (
        <div className="mt-1 w-full">
          <LinkButton
            onClick={refetchMotorIntelValuationCertificate}
            disabled={isRefetchingMotorIntelValuationCertificate}
          >
            <span
              className={
                isRefetchingMotorIntelValuationCertificate
                  ? "text-label !text-sm"
                  : "!text-secondary !text-sm"
              }
            >
              {isRefetchingMotorIntelValuationCertificate
                ? "Opening Valuation Certificate..."
                : "View MotorIntel Valuation Certificate"}
            </span>
          </LinkButton>
        </div>
      )}
    </div>
  );
};
