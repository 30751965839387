import { ButtonLink, SvgImage } from "components/shared";

import {
  paginationLeftIconTestID,
  paginationRightIconTestID,
  paginationTestID,
} from "../dataTestIDs";

import "./styles.scss";

export interface PaginationProps {
  title?: string;
  total: number;
  currentPage: number;
  pageLimit: number;
  autoScrollTop?: boolean;
  onNextPage: () => void;
  onPrevPage: () => void;
  onIndexedPage?: (index: number) => void;
}

export const Pagination = ({
  title = "Vehicles",
  total,
  currentPage,
  pageLimit,
  autoScrollTop = true,
  onNextPage,
  onPrevPage,
  onIndexedPage,
}: PaginationProps) => {
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage * pageLimit >= total;
  const lastIndex = currentPage * pageLimit;
  const firstIndex = isFirstPage
    ? currentPage
    : (currentPage - 1) * pageLimit + 1;

  const paginationSummary = `${title} ${firstIndex} - ${
    lastIndex > total ? total : lastIndex
  } of ${total}`;

  const scrollToTop = () => {
    if (!autoScrollTop) return;
    window.dispatchEvent(new Event("scroll-top"));
  };

  const handleIndexedPage = (selectedIndex: number) => {
    if (onIndexedPage) {
      onIndexedPage(selectedIndex);
      scrollToTop();
    }
  };

  const pageNumbers = () => {
    let numbers: React.ReactNode[] = [];
    if (total / pageLimit < 7) {
      for (let index = 0; index < total / pageLimit; index++) {
        numbers.push(
          <span
            key={index}
            className={index + 1 === currentPage ? "active" : ""}
            onClick={() => handleIndexedPage(index + 1)}
          >
            {index + 1}
          </span>
        );
      }
    } else if (currentPage < 5) {
      numbers = [1, 2, 3, 4, 5, "...", Math.ceil(total / pageLimit)].map(
        (index) => {
          if (typeof index === "number") {
            return (
              <span
                key={index}
                className={index === currentPage ? "active" : ""}
                onClick={() => handleIndexedPage(index)}
              >
                {index}
              </span>
            );
          } else {
            return <span key={index}>{index}</span>;
          }
        }
      );
    } else if (currentPage > 4 && currentPage < total / pageLimit - 3) {
      numbers = [
        1,
        "...",
        currentPage - 2,
        currentPage - 1,
        currentPage,
        currentPage + 1,
        currentPage + 2,
        "...",
        Math.ceil(total / pageLimit),
      ].map((index) => {
        if (typeof index === "number") {
          return (
            <span
              key={index}
              className={index === currentPage ? "active" : ""}
              onClick={() => handleIndexedPage(index)}
            >
              {index}
            </span>
          );
        } else {
          return <span key={index}>{index}</span>;
        }
      });
    } else {
      const lastPage = Math.ceil(total / pageLimit);

      numbers = [
        1,
        "...",
        lastPage - 4,
        lastPage - 3,
        lastPage - 2,
        lastPage - 1,
        lastPage,
      ].map((index) => {
        if (typeof index === "number") {
          return (
            <span
              key={index}
              className={index === currentPage ? "active" : ""}
              onClick={() => handleIndexedPage(index)}
            >
              {index}
            </span>
          );
        } else {
          return <span key={index}>{index}</span>;
        }
      });
    }

    return numbers;
  };

  // do not show pagination if no data
  if (total === 0) return <></>;

  return (
    <div className="pagination" data-testid={`${paginationTestID}-${title}`}>
      <span className="pagination__title">{paginationSummary}</span>
      <div className="hover:bg-[#EFEFEF] hover:rounded-full px-2">
        <ButtonLink
          onClick={() => {
            onPrevPage();
            scrollToTop();
          }}
          disabled={isFirstPage}
          dataTestID={`${paginationLeftIconTestID}-${title}`}
        >
          <SvgImage name="ArrowLeftIcon" />
        </ButtonLink>
      </div>
      {onIndexedPage && (
        <div className="pagination__numbers">{pageNumbers()}</div>
      )}
      <div className="hover:bg-[#EFEFEF] hover:rounded-full px-2">
        <ButtonLink
          onClick={() => {
            onNextPage();
            scrollToTop();
          }}
          disabled={isLastPage}
          dataTestID={`${paginationRightIconTestID}-${title}`}
        >
          <SvgImage name="ArrowRightIcon" />
        </ButtonLink>
      </div>
    </div>
  );
};
