import { ColumnDef } from "@tanstack/react-table";
import { PPSRStatus } from "enums/PPSRStatus";
import { useMemo } from "react";

import { SvgImage, Tooltip } from "components/shared";

import { PPSRCheckInfo } from "types/PPSRChecks";

import { DownloadCertificate } from "./DownloadCertificate";
import { PPSRRefresh } from "./PPSRRefresh";

type ColumnsProps = {
  onRefresh: () => void;
};

export const Columns = ({ onRefresh }: ColumnsProps) => {
  const columns = useMemo<ColumnDef<PPSRCheckInfo>[]>(
    () => [
      {
        id: "report_date",
        accessorKey: "report_date",
        header: "DATE",
        cell: (info) => {
          const rawDate = info?.row?.original?.report_date;

          return (
            <span
              className="truncate max-w-[150px] inline-block overflow-hidden whitespace-nowrap"
              title={rawDate}
            >
              {rawDate}
            </span>
          );
        },
      },
      {
        id: "dealership",
        accessorKey: "dealership.name",
        header: "DEALERSHIP",
        cell: (info) => (
          <span
            className="truncate max-w-[150px] inline-block overflow-hidden whitespace-nowrap"
            title={info?.row?.original?.dealership?.name}
          >
            {info?.row?.original?.dealership?.name || ""}
          </span>
        ),
      },
      {
        id: "created_by",
        accessorKey: "created_by.name",
        header: "USER",
        cell: (info) => info?.row?.original?.created_by.name || "",
      },
      { id: "vin", accessorKey: "vin", header: "VIN" },
      {
        id: "nevdis",
        accessorKey: "nevdis.registration",
        header: "REGO",
        cell: (info) => info?.row?.original?.nevdis?.registration || "",
      },
      {
        id: "vehicle",
        accessorKey: "name",
        header: "VEHICLE",
        cell: (info) => {
          const vehicleName = info?.row?.original?.name;

          if (vehicleName?.toLowerCase() === "unknown") {
            return <span>Not Found</span>;
          }

          return (
            <span
              className="truncate max-w-[150px] inline-block overflow-hidden whitespace-nowrap"
              title={info?.row?.original?.name}
            >
              {info?.row?.original?.name || ""}
            </span>
          );
        },
      },
      {
        id: "is_stolen",
        accessorKey: "is_stolen",
        header: "STOLEN",
        cell: (info) => {
          const vehicleName = info?.row?.original?.name;

          if (vehicleName?.toLowerCase() === "unknown") {
            return null;
          }

          return (
            <div
              className={`min-w-[50px] h-full flex items-center justify-center ${
                info.getValue() ? "bg-red-500 " : ""
              }`}
            >
              {info.getValue() ? "!!" : "-"}
            </div>
          );
        },
      },
      {
        id: "is_written_off",
        accessorKey: "is_written_off",
        header: "WOVR",
        cell: (info) => {
          const vehicleName = info?.row?.original?.name;

          if (vehicleName?.toLowerCase() === "unknown") {
            return null;
          }

          return (
            <div
              className={`min-w-[50px] h-full flex items-center justify-center ${
                info.getValue() ? "bg-red-500 " : ""
              }`}
            >
              {info.getValue() ? "!!" : "-"}
            </div>
          );
        },
      },
      {
        id: "has_security_interest",
        accessorKey: "has_security_interest",
        header: "INTERESTS",
        cell: (info) => {
          const vehicleName = info?.row?.original?.name;

          if (vehicleName?.toLowerCase() === "unknown") {
            return null;
          }

          const hasInterest = Boolean(info.getValue());
          const count = info?.row?.original?.result_count;

          return (
            <div
              className={`min-w-[50px] h-full flex items-center justify-center ${
                hasInterest ? "bg-red-500" : ""
              }`}
            >
              {hasInterest ? count : "-"}
            </div>
          );
        },
      },
      {
        id: "certificate_number",
        accessorKey: "certificate_number",
        header: "PPSR CERTIFICATE",
        cell: (info) => {
          const certificateNumber = info.getValue();
          const hasCertificate = info?.row?.original?.has_certificate;
          const ppsrID = info?.row?.original?.id;
          const status = info?.row?.original?.api_status?.toLowerCase();

          const vehicleName = info?.row?.original?.name;

          if (vehicleName?.toLowerCase() === "unknown") {
            return null;
          }

          if (!ppsrID || !hasCertificate) {
            if (status.includes("pending")) {
              return <span className="capitalize">Processing</span>;
            }

            return <span className="capitalize">{status}</span>;
          }

          return (
            <div className="flex gap-1 relative justify-between min-w-[150px]">
              <span>{(certificateNumber as string) || ""}</span>
              <DownloadCertificate ppsrID={ppsrID} />
            </div>
          );
        },
      },
      {
        id: "remaining_days",
        accessorKey: "remaining_days",
        header: "REFRESH",
        cell: (info) => {
          const days = info.getValue();
          const hasCertificate = info?.row?.original?.has_certificate;
          const status = info?.row?.original?.api_status?.toLowerCase();
          const vehicleName = info?.row?.original?.name;
          const searchID = info?.row?.original?.id;

          if (
            !hasCertificate ||
            !days ||
            vehicleName?.toLowerCase() === "unknown"
          ) {
            return null;
          }

          if (status === PPSRStatus.Changed) {
            return (
              <Tooltip
                show
                label="The security interest details have changed since your original search."
              >
                <div className="flex uppercase gap-1">
                  <span>Changed</span> <SvgImage name="WarningIcon" />
                </div>
              </Tooltip>
            );
          }

          return (
            <div className="flex gap-1 relative justify-between min-w-[80px]">
              {!!days && <span>{`${days} days`}</span>}
              {searchID && (
                <PPSRRefresh searchID={searchID} onRefresh={onRefresh} />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return { columns };
};
