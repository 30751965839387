import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import {
  FieldSearchBar,
  ListTenders,
  LoadVehicle,
  StatusBarTabs,
} from "components/app";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  ACTIVE,
  DEFAULT_ITEMS_PER_PAGE,
  StorageKeys,
  TENDER_MENU,
  TENDER_STATUS_LIST,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { NavigationMenuLabel } from "types";

import { TenderService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

export const TenderSellingList = () => {
  const { hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useSessionStorage("TENDER_SELLING", 1);
  const [scrollPosition, setScrollPosition] = useSessionStorage(
    StorageKeys.TenderSellingScroll,
    0
  );
  const [currentStatus, setCurrentStatus] = useSessionStorage(
    "TENDER_FILTER_SELLING_SELECTION",
    ACTIVE.key
  );

  const [{ label: statusLabel }] = TENDER_STATUS_LIST.filter((item) => {
    return item.key === currentStatus;
  });

  const canView = hasPermission(
    PermissionCategory.TENDER_SELLING,
    PermissionAction.VIEW
  );

  const { data, isInitialLoading, isRefetching } = useListQuery(
    {
      queryKey: StorageKeys.TenderSellingList,
      status: currentStatus,
      currentPage: currentPage,
      scrollPosition: scrollPosition,
    },
    TenderService.getTendersSelling,
    {
      enabled: canView,
    }
  );

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleChangeStatus = (status: string | number) => {
    setCurrentStatus(status);
    setCurrentPage(1);
  };

  const handleGoSummary = (id: string) => {
    setScrollPosition(
      `${document.getElementsByClassName("dashboard")[0].scrollTop}`
    );
    navigate(`${id}/summary`);
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Selling", SECTIONS.TENDER)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex w-full gap-5">
          <div className="flex flex-col gap-2.5 pt-2 w-430">
            <Breadcrumbs
              paths={[
                {
                  title: NavigationMenuLabel.TENDER,
                  route: TENDER_MENU.selling.route,
                },
                { title: "Selling", route: TENDER_MENU.selling.route },
              ]}
            />
            <div className="flex items-center gap-2">
              <div className="title">My Tenders</div>
              {isRefetching && <LoadingSpinner />}
            </div>
            <div className="flex flex-row gap-5 items-center">
              <Pagination
                title="Tenders"
                currentPage={currentPage}
                pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
                total={data?.meta?.total || 0}
                onNextPage={handleFetchNext}
                onPrevPage={handleFetchPrev}
                onIndexedPage={handleFetchSelected}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-center">
            <StatusBarTabs
              currentStatus={currentStatus}
              options={TENDER_STATUS_LIST}
              onSelect={handleChangeStatus}
            />
          </div>
        </div>
        <div className="dashboard__content">
          {!canView ? (
            <NoPermissionMessage
              viewPermissionCategory={PermissionCategory.TENDER_SELLING}
            />
          ) : isInitialLoading ? (
            <LoadVehicle />
          ) : !data || data.data.length === 0 ? (
            <div className="dashboard__content--empty">
              {`No ${statusLabel} Tenders`}
            </div>
          ) : (
            <ListTenders
              data={data.data}
              onSelect={handleGoSummary}
              currentStatus={currentStatus}
            />
          )}
        </div>
        <div className="dashboard__footer">
          <Pagination
            title="Tenders"
            currentPage={currentPage}
            pageLimit={data?.meta?.per_page || DEFAULT_ITEMS_PER_PAGE}
            total={data?.meta?.total || 0}
            onNextPage={handleFetchNext}
            onPrevPage={handleFetchPrev}
            onIndexedPage={handleFetchSelected}
          />
        </div>
      </div>
    </>
  );
};
