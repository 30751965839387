import { useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { ListVehicles, LoadVehicle, StatusBarTabs } from "components/app";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Breadcrumbs, Pagination } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ALL,
  DECLINED,
  DEFAULT_ITEMS_PER_PAGE,
  FLOW_TYPE,
  LOST,
  LOSTDEAL,
  LOSTSALE,
  NEGOTIATING,
  REJECTED,
  REVOKED,
  STATUS_LIST,
  StorageKeys,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";
import "./styles.scss";

import { IVehicleDetail, NavigationMenuLabel } from "types";

import { NegotiationService, ValuationService } from "api/client";

import { useSessionStorage } from "hooks";
import { useListQuery } from "hooks/useListQuery";

export const WholesaleNegotiationList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useSessionStorage(
    StorageKeys.WholesaleDealershipNegotiationList,
    1
  );

  const [currentStatus, setCurrentStatus] = useState<string | number>(ALL.key);
  const [isPaginationVisible, setIsPaginationVisible] = useState(true);

  const { hasPermission } = useContext(AuthContext);

  const isDirectOfferLostDeal = useMemo(
    () =>
      [LOSTSALE, REJECTED, REVOKED]
        .map((status) => status.key)
        .includes(currentStatus.toString()),
    [currentStatus]
  );

  const canView =
    hasPermission(PermissionCategory.WHOLESALE_INSPECTIONS, [
      PermissionAction.VIEW,
      PermissionAction.VIEW_ANY,
    ]) &&
    (!isDirectOfferLostDeal ||
      (isDirectOfferLostDeal &&
        hasPermission(
          PermissionCategory.DIRECT_OFFERS,
          PermissionAction.VIEW
        )));

  useEffect(() => {
    setIsPaginationVisible(canView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStatus]);

  const newStatus =
    currentStatus === LOST.key || currentStatus === LOSTDEAL.key
      ? DECLINED.key
      : currentStatus;

  const { data, isInitialLoading, isError } = useListQuery(
    {
      queryKey: StorageKeys.WholesaleDealershipNegotiationList,
      retailed: false,
      withParams:
        "dealership,owner,images,valuations,assessments,expenses,seller",
      status: newStatus !== ALL.key ? newStatus : "",
      currentPage: currentPage,
    },
    NegotiationService.getVehicleList,
    {
      notifyOnChangeProps: ["data"],
      enabled:
        (currentStatus === ALL.key ||
          currentStatus === NEGOTIATING.key ||
          currentStatus === LOST.key ||
          currentStatus === LOSTDEAL.key) &&
        (hasPermission(
          PermissionCategory.WHOLESALE_INSPECTIONS,
          PermissionAction.VIEW
        ) ||
          hasPermission(
            PermissionCategory.WHOLESALE_INSPECTIONS,
            PermissionAction.VIEW_ANY
          )),
    }
  );

  const vehicles = data?.data || [];

  const handleFetchNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleFetchPrev = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleFetchSelected = (index: number) => {
    setCurrentPage(index);
  };

  const handleChangeStatus = (status: string | number) => {
    setCurrentStatus(status);
    setCurrentPage(1);
  };

  const handleGoSummary = (
    id: string,
    isExternal: boolean,
    requestId?: string
  ) => {
    isExternal
      ? navigate(`${id}/${requestId}/summary`)
      : navigate(`${id}/summary`);
  };

  const getContent = () => {
    if (!canView) {
      return <NoPermissionMessage />;
    }

    if (isInitialLoading) return <LoadVehicle />;

    if (isError)
      return (
        <div className="dashboard__content--empty">
          An error has occurred. Please, try again.
        </div>
      );

    if (!vehicles?.length)
      return (
        <div className="dashboard__content--empty">
          No Inspections Created Yet
        </div>
      );

    return (
      <ListVehicles
        vehicles={vehicles}
        onSelect={handleGoSummary}
        flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Valued", SECTIONS.WHOLESALE_DEALERSHIP)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="flex w-full gap-5">
          <div className="flex flex-col gap-2.5 pt-2 w-430">
            <Breadcrumbs
              paths={[
                {
                  title: NavigationMenuLabel.WHOLESALE_DEALERSHIP,
                  route: WHOLESALE_DEALERSHIP_MENU[0].route,
                },
                { title: "Valued", route: WHOLESALE_DEALERSHIP_MENU[1].route },
              ]}
            />
            <div className="flex items-center gap-2">
              <div className="title">Valued</div>
            </div>
            <div className="flex flex-row gap-5 items-center">
              {isPaginationVisible && canView && (
                <Pagination
                  currentPage={currentPage}
                  pageLimit={DEFAULT_ITEMS_PER_PAGE}
                  total={data?.meta?.total || 0}
                  onNextPage={handleFetchNext}
                  onPrevPage={handleFetchPrev}
                  onIndexedPage={handleFetchSelected}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col gap-2 justify-center">
            <div>
              <StatusBarTabs
                currentStatus={currentStatus}
                options={STATUS_LIST.WholesaleNegotiation}
                onSelect={handleChangeStatus}
              />
              {(currentStatus === LOST.key ||
                STATUS_LIST.WholesaleLost.filter(
                  (item) => item.key === currentStatus
                ).length > 0) && (
                <div className="dashboard__subtoolbar">
                  {STATUS_LIST.WholesaleLost.map((item) => (
                    <button
                      key={item.key}
                      className={
                        currentStatus === item.key
                          ? `btn-subtool selected`
                          : "btn-subtool"
                      }
                      onClick={() => handleChangeStatus(item.key)}
                    >
                      {item.label}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="dashboard__content">{getContent()}</div>
        <div className="dashboard__footer">
          {isPaginationVisible && canView && (
            <Pagination
              currentPage={currentPage}
              pageLimit={DEFAULT_ITEMS_PER_PAGE}
              total={data?.meta?.total || 0}
              onNextPage={handleFetchNext}
              onPrevPage={handleFetchPrev}
              onIndexedPage={handleFetchSelected}
            />
          )}
        </div>
      </div>
    </>
  );
};
