import { useMutation } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { LinkButton } from "components/Button";
import { LoadingSpinner } from "components/Loading/LoadingSpinner";

import { showErrorToast } from "utils";
import { PPSR_CHANGED, PPSR_SUCCESS, PPSR_STATUSES } from "utils/ppsrStatuses";

import { IPPSR } from "types";

import { ReactComponent as FailedIcon } from "assets/images/failed-v2.svg";
import { ReactComponent as SuccessIcon } from "assets/images/passed.svg";
import { ReactComponent as ChangedIcon } from "assets/images/ppsr-changed.svg";

import { VehicleService } from "api/client";

import { Item } from "../Item";

interface DataContainerProps {
  data?: IPPSR;
  contractID: string;
}

export const DataContainer = ({ data, contractID }: DataContainerProps) => {
  const ppsrStatus = PPSR_STATUSES[data?.status as keyof typeof PPSR_STATUSES];

  const isPPSRChanged = ppsrStatus.name === PPSR_CHANGED.name;

  const { mutate: fetchCertificate, isLoading: isLoadingFetchCertificate } =
    useMutation(() => VehicleService.fetchPPSRCertificate(contractID), {
      onSuccess: (data) => {
        if (data) {
          const newWindow = window.open(data, "_blank", "noopener,noreferrer");
          if (newWindow) newWindow.opener = null;
        }
      },
      onError: () => {
        showErrorToast("Failed to Download. Please try again.");
      },
    });

  const renderPPSRIcon = () => {
    if (ppsrStatus.name === PPSR_CHANGED.name) {
      return <ChangedIcon />;
    }
    if (ppsrStatus.name === PPSR_SUCCESS.name) {
      return <SuccessIcon />;
    }
    return <FailedIcon />;
  };

  return (
    <div className="grid grid-cols-2 gap-x-10 my-5">
      <Item
        title="Report Result"
        value={
          <span
            className={`flex uppercase ${ppsrStatus.color} font-medium gap-1 mr-1`}
          >
            {ppsrStatus.label}
            {renderPPSRIcon()}
          </span>
        }
      />
      <Item
        title="Report Date"
        value={
          data?.report_date &&
          DateTime.fromJSDate(new Date(data?.report_date)).toFormat(
            "dd MMM yyyy"
          )
        }
      />
      <Item
        title="Interests"
        value={
          !isPPSRChanged &&
          (data?.has_security_interest
            ? "Finance Interest Recorded"
            : "No finance interest recorded")
        }
        failedStatus={data?.has_security_interest}
      />
      <Item title="Certificate Number" value={data?.certificate_number} />
      <Item
        title="Stolen"
        value={
          !isPPSRChanged &&
          (data?.is_stolen
            ? "Stolen status reported"
            : "No stolen status reported")
        }
        failedStatus={data?.is_stolen}
      />
      <Item title="Search Number" value={data?.search_number} />
      <Item
        title="Written Off"
        value={
          !isPPSRChanged &&
          (data?.is_written_off
            ? "Written-off status reported"
            : "No written-off status reported")
        }
        failedStatus={data?.is_written_off}
      />
      <Item
        title="Certificate PDF"
        value={
          <div className="mt-1 w-full">
            <LinkButton
              onClick={fetchCertificate}
              disabled={isLoadingFetchCertificate}
            >
              {isLoadingFetchCertificate ? (
                <LoadingSpinner />
              ) : ["available", "unavailable"].includes(
                  data?.certificate_status || ""
                ) ? (
                <span className="!text-secondary !text-sm">View</span>
              ) : (
                <span className={"text-label !text-sm"}>Processing...</span>
              )}
            </LinkButton>
          </div>
        }
      />
    </div>
  );
};
