import classNames from "classnames";
import { forwardRef, InputHTMLAttributes, LegacyRef } from "react";

import "./styles.scss";

export interface FieldTextProps extends InputHTMLAttributes<HTMLInputElement> {
  isCapitalized?: boolean;
  isBgRequired?: boolean;
}

export const FieldText = forwardRef(
  (props: FieldTextProps, ref: LegacyRef<HTMLInputElement>) => {
    return (
      <div className="input-wrapper">
        {props.title && <label className="input-label">{props.title}</label>}
        <input
          className={classNames("input", {
            capitalize: props.isCapitalized,
            "input-required": props.isBgRequired,
          })}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
