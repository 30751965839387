import { useState } from "react";
import { useForm } from "react-hook-form";

import { modalOfferNegotiationTestID } from "components/app/dataTestIDs";
import { ModalContainer } from "components/ModalContainer";
import {
  Button,
  FieldText,
  FieldTextarea,
  SvgImage,
  TextError,
} from "components/shared";

import { checkIsValidNumber, formatNumber, NEGOTIATING } from "utils";

export interface IOfferNegotiation {
  amount: string;
  comment: string;
}

export interface OfferNegotiationModalProps {
  title?: string;
  description?: string;
  isRetail: boolean;
  isAccepted: boolean;
  isOpen: boolean;
  canClose?: boolean;
  size?: "auto" | "responsive";
  isSubmitting: boolean;
  onSubmit: (data: IOfferNegotiation) => void;
  onClose: () => void;
  dataTestID?: string;
  initialOfferAmount?: string;
  status?: string;
}

export const OfferNegotiationModal = ({
  title,
  description,
  isRetail,
  isAccepted,
  isOpen,
  canClose = true,
  size = "auto",
  isSubmitting,
  onSubmit,
  onClose,
  initialOfferAmount,
  dataTestID,
  status,
}: OfferNegotiationModalProps) => {
  const [isValidInput, setIsValidInput] = useState(true);
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IOfferNegotiation>({
    defaultValues: {
      amount: initialOfferAmount || "",
      comment: "",
    },
  });

  const inputFieldName = "amount";
  const amountType =
    isRetail && status === NEGOTIATING.key
      ? "Offer Amount"
      : "Acquisition Price";

  const onChangeAmount = (value: string) => {
    setValue(inputFieldName, formatNumber(value.replaceAll(",", "")));

    setIsValidInput(checkIsValidNumber(getValues(inputFieldName)));

    clearErrors(inputFieldName);
  };

  return (
    <ModalContainer
      title={title}
      description={description}
      isOpen={isOpen}
      canClose={canClose}
      size={size}
      onClose={onClose}
    >
      <div
        className="w-[310px] mx-0 md:-mx-5 mt-4 -mb-4"
        data-testid={dataTestID || modalOfferNegotiationTestID}
      >
        <div className="relative">
          <FieldText
            title={`${amountType}*`}
            type="text"
            {...register(inputFieldName, { required: true })}
            placeholder="$ Enter amount"
            onChange={(e) => onChangeAmount(e.target.value)}
          />
          {title !== "Acquisition Price" && (
            <span className="absolute top-8 right-3">
              <SvgImage name="EditPen" />
            </span>
          )}
          <div>
            {errors.amount && <TextError error={`${amountType} is required`} />}
          </div>
          <div>
            {!isValidInput && <TextError error="Please enter numbers only" />}
          </div>
        </div>
        {(!isAccepted || isRetail) && (
          <div className="mt-6">
            <FieldTextarea
              title="Comments"
              type="text"
              {...register("comment", { required: false })}
              placeholder="Enter comments here"
            />
          </div>
        )}
        <div className="flex justify-between mt-8">
          <div className="w-[130px] mx-auto">
            <Button
              className="bg-white shadow-md rounded-60 border border-solid border-whiteWhale"
              type="button"
              onClick={onClose}
              variant="secondary"
            >
              <span className="uppercase text-darkKnight text-sm font-bold">
                cancel
              </span>
            </Button>
          </div>
          <div className="w-[130px] mx-auto">
            <Button
              type="submit"
              isLoading={isSubmitting}
              onClick={handleSubmit(onSubmit)}
              disabled={!isValidInput}
            >
              <span className="text-lighttxt text-sm font-bold uppercase">
                confirm
              </span>
            </Button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
