import { useQueryClient } from "@tanstack/react-query";
import { encode } from "js-base64";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import {
  DEVICE_TYPES,
  KEEP_SIGNED_IN,
  USER_ACCESS_TOKEN,
} from "utils/constants";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IAuth } from "types";

import { AuthService } from "api/client";

import { useLocalStorage, useSessionStorage } from "hooks";

import { useDealership } from "./DealershipContext";

export interface ILoginRequestProps {
  email: string;
  password: string;
  keepSignedIn: boolean;
}
interface IResetPasswordRequestProps {
  email: string;
  password: string;
  password_confirmation: string;
}
interface IContextProps {
  user: IAuth | null | undefined;
  userID: string;
  authToken: string;
  isLoading: boolean;
  updateDisplayName: (name: string) => void;
  signIn: (data: ILoginRequestProps) => Promise<IAuth | null>;
  resetPassword: (data: IResetPasswordRequestProps) => Promise<void>;
  logout: () => Promise<void>;
  hasPermission: (
    category: PermissionCategory | PermissionCategory[],
    action?: PermissionAction | PermissionAction[]
  ) => boolean;
  afterSigninPermissionCheck: (
    permission: string[],
    category: PermissionCategory | PermissionCategory[],
    action?: PermissionAction | PermissionAction[]
  ) => boolean;
}

export const AuthContext = createContext<IContextProps>({
  user: null,
  userID: "",
  authToken: "",
  isLoading: false,
  updateDisplayName: (_name: string) => {},
  signIn: async (_data: ILoginRequestProps) => null,
  resetPassword: async (_data: IResetPasswordRequestProps) => {},
  logout: async () => {},
  hasPermission: (
    _category: PermissionCategory | PermissionCategory[],
    _action?: PermissionAction | PermissionAction[]
  ) => false,
  afterSigninPermissionCheck: (
    _permissions: string[],
    _category: PermissionCategory | PermissionCategory[],
    _action?: PermissionAction | PermissionAction[]
  ) => false,
});

export const AuthProvider = ({ children }: any) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [user, setUser] = useState<IAuth>();
  const [userID, setUserID] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useLocalStorage(KEEP_SIGNED_IN, null);
  const [localStorageAccessToken, setLocalStorageAccessToken] = useLocalStorage(
    USER_ACCESS_TOKEN,
    ""
  );
  const [sessionStorageAcessToken, setSessionStorageAccessToken] =
    useSessionStorage(USER_ACCESS_TOKEN, "");

  const [authToken, setAuthToken] = useState(
    keepSignedIn ? localStorageAccessToken : sessionStorageAcessToken
  );
  const { setDealershipID } = useDealership();
  const keepSignedInRef = useRef(keepSignedIn);
  const authTokenRef = useRef(authToken);

  const saveAuthToStorage = useCallback(
    (keepSignedIn: boolean, accessToken: string) => {
      if (keepSignedIn) {
        setLocalStorageAccessToken(encode(accessToken ?? "", true));
      } else {
        setSessionStorageAccessToken(encode(accessToken ?? "", true));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**********************************************
   * initial loading when bootstrapping
   **********************************************/

  useEffect(() => {
    const bootstrapAsync = async () => {
      setLoading(true);

      try {
        if (authTokenRef.current) {
          await AuthService.validateAuth(
            isTablet ? DEVICE_TYPES.TABLET : DEVICE_TYPES.DESKTOP
          )
            .then((response) => {
              response.message !== "OK" &&
                navigate("/login", { replace: true });
              setUser(response);
              setUserID(response?.data?.user?.id);
            })
            .catch(() => {
              setLocalStorageAccessToken("");
              setSessionStorageAccessToken("");
            });
        } else {
          saveAuthToStorage(false, "");
        }
      } catch (err) {
        saveAuthToStorage(false, "");
      }
      setLoading(false);
    };

    bootstrapAsync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveAuthToStorage]);

  useEffect(() => {
    keepSignedInRef.current = keepSignedIn;
  }, [keepSignedIn]);

  useEffect(() => {
    authTokenRef.current = authToken;
  }, [authToken]);

  useEffect(() => {
    setAuthToken(
      keepSignedIn ? localStorageAccessToken : sessionStorageAcessToken
    );
  }, [keepSignedIn, sessionStorageAcessToken, localStorageAccessToken]);

  /**********************************************
   Context Functions
   **********************************************/
  const signIn = async (data: ILoginRequestProps) => {
    setLoading(true);
    try {
      const userAuth = await AuthService.login({
        token: encode(`${data.email}:${data.password}`),
        device_name: isTablet ? DEVICE_TYPES.TABLET : DEVICE_TYPES.DESKTOP,
      });

      saveAuthToStorage(data.keepSignedIn, userAuth.data.access_token);

      setKeepSignedIn(data.keepSignedIn);

      setUser(userAuth);

      setUserID(userAuth?.data?.user?.id);

      return userAuth;
    } catch (err) {
      console.log("login error: ", err);
      saveAuthToStorage(false, "");
    } finally {
      setLoading(false);
    }

    return null;
  };

  const resetPassword = async (data: IResetPasswordRequestProps) => {
    setLoading(true);
    try {
      const result = await AuthService.forgotPassword(data);
      if (result) {
        await signIn({
          email: data.email,
          password: data.password,
          keepSignedIn: false,
        });
        setUser(result);
        saveAuthToStorage(false, result.data.access_token);
      }
    } catch (err) {
      saveAuthToStorage(false, "");
    }
    setLoading(false);
  };

  const logout = async () => {
    setLoading(true);
    try {
      const result = await AuthService.logout();
      if (result) {
        setDealershipID(null);
        saveAuthToStorage(keepSignedIn, "");
        setUser(undefined);
        setUserID("");
        queryClient.clear();
        localStorage.clear();
        sessionStorage.clear();
      }
    } catch (err) {
      console.log("logout failed: ", err);
    }
    setLoading(false);
  };

  const updateDisplayName = (name: string) => {
    const updatedUser = { ...user };
    if (updatedUser.data) updatedUser.data.user.name = name;
    setUser(updatedUser as IAuth);
  };

  const getPermissionCategory = (permission: string): PermissionCategory =>
    permission.substring(0, permission.indexOf(":")) as PermissionCategory;

  const getPermissionAction = (permission: string): PermissionAction =>
    permission.substring(permission.indexOf(":") + 1) as PermissionAction;

  const afterSigninPermissionCheck = (
    permissions: string[],
    category: PermissionCategory | PermissionCategory[],
    action?: PermissionAction | PermissionAction[]
  ) => {
    return permissions.some(
      (permission) =>
        (Array.isArray(category)
          ? category.includes(getPermissionCategory(permission))
          : getPermissionCategory(permission) === category.toString()) &&
        (!action ||
          (Array.isArray(action)
            ? action.includes(getPermissionAction(permission))
            : getPermissionAction(permission) === action.toString()))
    );
  };

  const hasPermission = (
    category: PermissionCategory | PermissionCategory[],
    action?: PermissionAction | PermissionAction[]
  ) => {
    if (!user?.data.user.permissions) return false;

    return user.data.user.permissions.some(
      (permission) =>
        (Array.isArray(category)
          ? category.includes(getPermissionCategory(permission))
          : getPermissionCategory(permission) === category.toString()) &&
        (!action ||
          (Array.isArray(action)
            ? action.includes(getPermissionAction(permission))
            : getPermissionAction(permission) === action.toString()))
    );
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userID,
        authToken,
        isLoading,
        updateDisplayName,
        signIn,
        resetPassword,
        logout,
        hasPermission,
        afterSigninPermissionCheck,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
