import { ISearchPlateParams, IVehicle } from "types";

import { api, url } from "api";

export const VehicleSearchService = {
  searchYears: () => api.get(`${url.searchYears}`),

  searchMakes: (params: any) => api.get(`${url.searchMakes}`, params),

  searchModels: (params: any) => api.get(`${url.searchModels}`, params),

  searchVariants: (payload: any) => api.post(`${url.searchVariants}`, payload),

  searchNvic: (nvic: string) => api.get(`${url.searchNVIC}/${nvic}`),

  searchPlate: (params: ISearchPlateParams, inspectionID?: string) =>
    api.get(`${url.searchPlate}/${inspectionID || ""}`, params) as Promise<{
      data: IVehicle;
      message: string | null;
    }>,
};
