import { useEffect, useRef } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

export const useScrollRestore = () => {
  const scrollPositions = useRef<Record<string, number>>({});
  const lastRoute = useRef<string | null>(null);
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    const currentPath = location.pathname;

    const saveScrollPosition = () => {
      const scrollY =
        document.getElementsByClassName("dashboard")[0]?.scrollTop || 0;
      scrollPositions.current[currentPath] = scrollY;
    };

    const restoreScrollPosition = () => {
      if (lastRoute.current?.includes("/summary")) {
        const savedScrollPosition = scrollPositions.current[currentPath] || 0;

        const dashboard = document.getElementsByClassName("dashboard")[0];
        if (dashboard) {
          dashboard.scrollTo(0, savedScrollPosition);
        } else {
          window.scrollTo(0, savedScrollPosition);
        }
      }
    };

    if (navigationType === "POP") {
      restoreScrollPosition();
    }
    lastRoute.current = currentPath;

    document
      .getElementsByClassName("dashboard")[0]
      ?.addEventListener("scroll", saveScrollPosition);

    return () => {
      document
        .getElementsByClassName("dashboard")[0]
        ?.removeEventListener("scroll", saveScrollPosition);
    };
  }, [location, navigationType]);

  return null;
};
