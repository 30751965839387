export enum PermissionCategory {
  CENTRAL_DEALERSHIP = "central-dealership",
  DEALERSHIP_OFFER = "dealership-offer",
  DEALERSHIP_POOL = "dealership-pool",
  DEALERSHIP_VALUATIONS = "dealership-valuations",
  DIRECT_OFFERS = "direct-offers",
  EXTERNAL_VALUATIONS = "external-valuations",
  FACTORY_OPTIONS = "factory-options",
  MANAGE_USERS = "manage-users",
  MOTOR_MARKET_SELLING = "motor-market-selling",
  MOTOR_MARKET_BUYING = "motor-market-buying",
  MOTORINTEL_VALUATIONS = "mp-valuations",
  PPSR_SEARCH = "ppsr-search",
  REPORTS = "reports",
  PRICE_GUIDE = "price-guide",
  RETAIL_INSPECTIONS = "retail-inspections",
  TENDER_BUYING = "tender-buying",
  TENDER_SELLING = "tender-selling",
  USERS = "users",
  WHOLESALE_INSPECTIONS = "wholesale-inspections",
  WHOLESALE_VALUATIONS = "wholesale-valuations",
  VALUATION_CERTIFICATE = "valuation-certificate",
  PPSR_REQUEST = "ppsr-request",
}

export enum NavigatePermissionCategory {
  REPORTS = "reports",
  RETAIL_INSPECTIONS = "retail-inspections",
  WHOLESALE_INSPECTIONS = "wholesale-inspections",
  TENDER_SELLING = "tender-selling",
  TENDER_BUYING = "tender-buying",
  MOTOR_MARKET_SELLING = "motor-market-selling",
  MOTOR_MARKET_BUYING = "motor-market-buying",
}

export enum PermissionAction {
  BID = "bid",
  CREATE = "create",
  DELETE = "delete",
  REQUEST = "request",
  REVERT = "revert",
  SELL = "sell",
  UPDATE = "update",
  VIEW = "view",
  VIEW_ANY = "view-any",
}
