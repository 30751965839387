import { useMutation, useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ModalConfirm,
  ModalOfferNegotiation,
  ModalSaleDetails,
  ModalSentValuation,
} from "components/app";
import { INegotiationOffer } from "components/app/Modal/Negotiation/ModalOfferNegotiation";
import { DirectOfferBuyNow } from "components/DirectOffer/DirectOfferBuyNow";
import { DirectOfferModal } from "components/Modal/DirectOfferModal/DirectOfferModal";
import { OfferNegotiationModal } from "components/Modal/OfferNegotiationModal";
import { Button, ButtonLink, Modal } from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  ACCEPTED,
  ACQUIRED,
  BUYNOW,
  canManageDealership,
  DECLINED,
  DRAFT,
  FeatureRoute,
  FLOW_TYPE,
  formatNumber,
  LOSTSALE,
  NEGOTIATING,
  PENDINGSALE,
  REJECTED,
  REVIEW,
  REVOKED,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { RETAIL, WHOLESALE } from "utils/inspectionOptions";
import { isValuationContractType } from "utils/inspections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IVehicleSummary } from "types";

import {
  AcquisitionService,
  DealershipInspectionService,
  NegotiationService,
} from "api/client";

import { ModalMissingInfo } from "../Modal";
import { FooterSection } from "./FooterSection";
import { MoreActions } from "./MoreActions";

interface FooterProps {
  data: IVehicleSummary;
  flow: FLOW_TYPE;
  canSellVehicle?: boolean;
  canRevertVehicle?: boolean;
  onRefresh?: () => void;
}

export const Footer = ({
  data,
  flow,
  canSellVehicle = true,
  canRevertVehicle = true,
  onRefresh,
}: FooterProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { user, userID, hasPermission } = useContext(AuthContext);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showMissingModal, setShowMissingModal] = useState(false);
  const [showSaleDetailsModal, setShowSaleDetailsModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [showDirectOfferModal, setShowDirectOfferModal] = useState(false);
  const [showBackToNegotiatingModal, setBackToNegotiatingModal] =
    useState(false);
  const [offerState, setOfferState] = useState<
    "ACCEPTED" | "DECLINED" | undefined
  >();

  const {
    status,
    is_contract_owner,
    contract_id,
    request_id,
    retailed,
    acquisitions,
  } = data;

  const isAcceptedOffer = offerState === "ACCEPTED";

  const isRetailDealership = pathname.includes(FeatureRoute.RetailDealership);

  const isWholesaleDealership = pathname.includes(
    FeatureRoute.WholesaleDealership
  );

  const successMessage = isRetailDealership
    ? status.name === ACCEPTED.key
      ? "Vehicle Acquired"
      : "Saved Successfully"
    : "Vehicle Acquired";

  const { mutate: sendForValuation, isLoading: isSendingValuation } =
    useMutation(
      () => DealershipInspectionService.sendForValuation(params.id || ""),
      {
        onSuccess: () => {
          setShowRequestModal(true);
        },
      }
    );

  const { mutate: negotiateOffer, isLoading: isNegotiatingOffer } = useMutation(
    (payload: { offer_type: string; offer_amount: string; comment: string }) =>
      NegotiationService.negotiationOffer(contract_id, payload),
    {
      onSuccess: () => {
        showSuccessToast(successMessage);
        if (status?.name === ACCEPTED.key && isRetailDealership) {
          navigate(
            `${FeatureRoute.RetailDealershipStocklist}/${contract_id}/summary`
          );
        } else if (status?.name === NEGOTIATING.key && isWholesaleDealership) {
          navigate(
            `${FeatureRoute.WholesaleDealershipStocklist}/${contract_id}/summary`
          );
        } else {
          setShowOfferModal(false);
          setOfferState(undefined);
          if (onRefresh) onRefresh();
        }
        setOfferState(undefined);
      },
      onError: () => {
        showErrorToast("Failed to Save");
        setOfferState(undefined);
      },
    }
  );

  const { mutate: handleRevert, isLoading: isGoingBackNegotiating } =
    useMutation(
      (payload: { reason: string }) =>
        NegotiationService.negotiationRevert(contract_id, payload),
      {
        onSuccess: () => {
          onRefresh?.();
          showSuccessToast("Successfully Moved");
        },
        onError: () => {
          showErrorToast("Failed to Move");
        },
      }
    );

  const { mutate: toggleRetailed, isLoading: togglingRetailWholesale } =
    useMutation(
      (transferTo?: string | number) =>
        AcquisitionService.toggleRetailWholesale(
          contract_id,
          transferTo ? transferTo : retailed ? WHOLESALE.key : RETAIL.key
        ),
      {
        onSuccess: (value) => {
          showSuccessToast("Saved successfully");
          navigate(
            `${
              value?.data?.is_retailed
                ? FeatureRoute.RetailDealershipStocklist
                : FeatureRoute.WholesaleDealershipStocklist
            }/${contract_id}/summary`
          );
          onRefresh?.();
        },
        onError: () => {
          showErrorToast("Failed to Save");
        },
      }
    );

  const handleSendValuation = () => {
    if (!data.vehicle.odometer) {
      setShowMissingModal(true);
    } else {
      sendForValuation();
    }
  };

  const onNegotiateOffer = (negotiation: INegotiationOffer) => {
    negotiateOffer({
      offer_type:
        status?.name === ACCEPTED.key
          ? ACQUIRED.key
          : isAcceptedOffer
          ? ACCEPTED.key
          : DECLINED.key,
      offer_amount: negotiation.amount.replaceAll(",", ""),
      comment: negotiation.comment,
    });
  };

  const handleSell = () => {
    setShowSaleDetailsModal(true);
  };

  const handleBackToNegotiating = () => {
    setBackToNegotiatingModal(true);
  };

  const closeMissingModal = () => {
    setShowMissingModal(false);
    sendForValuation();
  };

  const handleCloseVehicleSummary = () => {
    setShowRequestModal(false);
    navigate(-1);
  };

  const handleCloseModalSendValuation = () => {
    setShowRequestModal(false);
    onRefresh && onRefresh();
  };

  const canTransferVehicle: boolean =
    hasPermission(
      PermissionCategory.RETAIL_INSPECTIONS,
      PermissionAction.CREATE
    ) &&
    hasPermission(
      PermissionCategory.WHOLESALE_INSPECTIONS,
      PermissionAction.CREATE
    );

  const canDeleteVehicle: boolean = isRetailDealership
    ? hasPermission(
        PermissionCategory.RETAIL_INSPECTIONS,
        PermissionAction.DELETE
      )
    : hasPermission(
        PermissionCategory.WHOLESALE_INSPECTIONS,
        PermissionAction.DELETE
      );

  const canDirectOffer: boolean = hasPermission(
    PermissionCategory.DIRECT_OFFERS,
    PermissionAction.CREATE
  );

  const canViewDirectOffer: boolean = hasPermission(
    PermissionCategory.DIRECT_OFFERS,
    [PermissionAction.VIEW, PermissionAction.VIEW_ANY]
  );

  if (!user) {
    return <></>;
  }

  const { dealership } = user.data;

  if (status?.name === DRAFT.key || status?.name === REVIEW.key) {
    return (
      <>
        <FooterSection
          title="Request Valuation"
          description="The more accurate and complete information provided, the more
        accurate the valuation. Ensure all required fields are completed
        before sending."
        >
          <div className="btn__valuation">
            <Button
              type="button"
              isLoading={isSendingValuation}
              onClick={handleSendValuation}
            >
              <span>send for valuation</span>
            </Button>
          </div>
          <Modal
            open={showRequestModal}
            canClose={false}
            size="auto"
            closeModal={handleCloseModalSendValuation}
          >
            <ModalSentValuation onClose={handleCloseVehicleSummary} />
          </Modal>
          <Modal
            open={showMissingModal}
            size="auto"
            title="Missing Information"
            canClose={false}
            closeModal={() => setShowMissingModal(false)}
          >
            <ModalMissingInfo
              onCloseModal={closeMissingModal}
              contract_id={contract_id}
            />
          </Modal>
        </FooterSection>
        {status.name === DRAFT.key && (
          <MoreActions
            contractID={contract_id}
            canDeleteVehicle={canDeleteVehicle}
          />
        )}
      </>
    );
  }
  if (
    status?.name === NEGOTIATING.key &&
    !canManageDealership(dealership?.type) &&
    is_contract_owner
  ) {
    return (
      <FooterSection
        title="Client Outcome"
        description="If the client wishes to trade/sell their vehicle, accept their offer to submit the final offer amount."
      >
        <div className="btn__draft">
          <Button
            type="button"
            onClick={() => {
              setShowOfferModal(true);
              setOfferState("DECLINED");
            }}
          >
            <span>DECLINED</span>
          </Button>
        </div>
        <div className="btn__valuation">
          <Button
            type="button"
            onClick={() => {
              setShowOfferModal(true);
              setOfferState("ACCEPTED");
            }}
          >
            <span>CUSTOMER ACCEPTED</span>
          </Button>
        </div>
        <OfferNegotiationModal
          title={
            isAcceptedOffer && !retailed
              ? "Acquisition Price"
              : isAcceptedOffer
              ? "Accepted Offer"
              : "Declined Offer"
          }
          isRetail={retailed || false}
          initialOfferAmount={formatNumber(
            data.valuations.internal?.offer?.amount
          )}
          isAccepted={isAcceptedOffer}
          isOpen={showOfferModal}
          isSubmitting={isNegotiatingOffer}
          onSubmit={onNegotiateOffer}
          status={status.name}
          onClose={() => {
            setShowOfferModal(false);
            setOfferState(undefined);
          }}
        />
      </FooterSection>
    );
  }
  if (status?.name === ACCEPTED.key) {
    return (
      <FooterSection
        title="Vehicle Acquired"
        description="Client's vehicle received and processed."
      >
        <div className="btn__valuation !m-0">
          <Button type="button" onClick={() => setShowOfferModal(true)}>
            <span>ADD TO STOCKLIST</span>
          </Button>
        </div>
        <Modal
          open={showOfferModal}
          size="auto"
          title={"Acquisition Price"}
          closeModal={() => setShowOfferModal(false)}
        >
          <ModalOfferNegotiation
            onCloseModal={() => setShowOfferModal(false)}
            onSubmit={onNegotiateOffer}
            isSubmitting={isNegotiatingOffer}
            isAccepted={status.name === ACCEPTED.key}
            initialOfferAmount={formatNumber(
              data.valuations.internal?.offer?.amount
            )}
          />
        </Modal>
      </FooterSection>
    );
  }
  if (
    [BUYNOW.key, REJECTED.key, REVOKED.key, LOSTSALE.key].includes(status.name)
  ) {
    return (
      <FooterSection title="Direct Offer">
        <div className="block w-full">
          <DirectOfferBuyNow
            offerID={data?.offer_id!}
            offerAmount={data?.offer_amount}
            status={status.name}
            contractID={
              data?.acquisitions?.acquisition?.new_contract_id || contract_id
            }
            requestID={request_id}
            onRefresh={onRefresh}
          />
        </div>
      </FooterSection>
    );
  }
  if ([ACQUIRED.key, PENDINGSALE.key].includes(status.name)) {
    const isAcquired = status.name === ACQUIRED.key;

    if (!isAcquired && !canViewDirectOffer) {
      return null;
    }

    return (
      <>
        <FooterSection
          title={isAcquired ? "Direct Sale Options" : "Direct Offer"}
          description={
            isAcquired
              ? "Direct offers and sales take place here."
              : "Direct Offer has been sent for this vehicle. Awaiting the buyer's response. You can view, edit, or withdraw the offer submitted to your wholesale partners."
          }
        >
          <div className="w-full">
            <div className="flex mb-5">
              {(canDirectOffer || !isAcquired) && (
                <div
                  className={classNames("btn__sell__other", {
                    pending__sale: !isAcquired,
                    "w-full": !isAcquired,
                  })}
                >
                  <Button
                    type="button"
                    isLoading={false}
                    variant={isAcquired ? "secondary" : undefined}
                    className="w-full"
                    onClick={() => setShowDirectOfferModal(true)}
                  >
                    <span className="mx-3 px-3">
                      {isAcquired ? "DIRECT OFFER" : "VIEW"}
                    </span>
                  </Button>
                </div>
              )}
              {canSellVehicle && isAcquired && (
                <div
                  className={classNames("btn__sell__mp", {
                    "ml-2": canDirectOffer,
                    "w-full": !canDirectOffer,
                  })}
                >
                  <Button type="button" onClick={handleSell}>
                    <span>Sell</span>
                  </Button>
                </div>
              )}
            </div>
            {flow === FLOW_TYPE.RETAIL_DEALERSHIP && isAcquired && (
              <>
                <div className="dashboard__content__footer--additional">
                  {canTransferVehicle && (
                    <ButtonLink
                      disabled={togglingRetailWholesale}
                      onClick={() => toggleRetailed(undefined)}
                    >
                      {retailed ? "Move To Wholesale" : "Move To Retail"}
                    </ButtonLink>
                  )}
                  {!isValuationContractType(data) &&
                    (canRevertVehicle || canDirectOffer) && (
                      <ButtonLink
                        disabled={isGoingBackNegotiating}
                        onClick={handleBackToNegotiating}
                      >
                        Move Back To Pending Deal Status
                      </ButtonLink>
                    )}
                </div>
                <Modal
                  open={showBackToNegotiatingModal}
                  size="auto"
                  canClose={false}
                  closeModal={() => setBackToNegotiatingModal(false)}
                >
                  <ModalConfirm
                    onCancel={() => setBackToNegotiatingModal(false)}
                    onConfirm={() =>
                      handleRevert({ reason: "Customer cancelled" })
                    }
                  />
                </Modal>
              </>
            )}
            {flow === FLOW_TYPE.WHOLESALE_DEALERSHIP && canTransferVehicle && (
              <div className="dashboard__content__footer--additional">
                <ButtonLink
                  disabled={togglingRetailWholesale}
                  onClick={() => toggleRetailed(RETAIL.key)}
                >
                  Move To Retail
                </ButtonLink>
              </div>
            )}
          </div>
          <Modal
            open={showSaleDetailsModal}
            size="auto"
            title="Sale Details"
            closeModal={() => setShowSaleDetailsModal(false)}
          >
            <ModalSaleDetails
              onCloseModal={() => setShowSaleDetailsModal(false)}
              contractId={contract_id}
              data={data}
              dealershipType={user.data.dealership?.type}
              dealershipId={user.data.dealership?.id}
              onRefresh={() => onRefresh?.()}
            />
          </Modal>
          <DirectOfferModal
            isOpen={showDirectOfferModal}
            onClose={() => setShowDirectOfferModal(false)}
            onRefresh={onRefresh}
          />
        </FooterSection>
      </>
    );
  }

  return <></>;
};
