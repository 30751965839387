import { Disclosure } from "@headlessui/react";
import { FC, useCallback, useContext, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  NavigationTestID,
  theSidebarTestID,
} from "components/layouts/dataTestIDs";
import { NoAccessNavigation } from "components/Navigation/NoAccessNavigation";
import { SvgImage } from "components/shared";
import { ButtonWithIcon } from "components/shared/Buttons/ButtonWithIcon";
import { Support } from "components/Support";

import { AuthContext } from "context/AuthContext";

import {
  ACCESS_DENIED_ROUTE,
  DASHBOARD_MENU,
  PPSR_LIST_MENU,
  MOTOR_MARKET_MENU,
  RETAIL_DEALERSHIP_MENU,
  SETTINGS,
  TENDER_MENU,
  WHOLESALE_DEALERSHIP_MENU,
} from "utils";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { NavigationMenuLabel } from "types";

import { ReactComponent as ArrowLeftIcon } from "assets/images/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/images/arrow-right.svg";

import { useManageDisclosure } from "hooks/useManageDisclosure";

import { NavHeader } from "./NavHeader";
import { NavItem, NavItemProps } from "./NavItem";

import "../../styles.scss";

export interface TheSidebarProps {
  collapsed: boolean;
  onToggleCollapsed: () => void;
  dataTestID?: string;
}

export type TenderMenuType = {
  selling: NavItemProps;
  buying: NavItemProps;
};

export type MotorMarketMenuType = {
  myListings: NavItemProps;
  buying: NavItemProps;
};

const APP_VERSION = process.env.REACT_APP_VERSION;

export const TheSidebar: FC<TheSidebarProps> = ({
  collapsed,
  onToggleCollapsed,
  dataTestID,
}) => {
  const location = useLocation();
  const { user, hasPermission, logout } = useContext(AuthContext);

  const isActive = useCallback(
    (navItem: NavItemProps) => {
      if (navItem.label === DASHBOARD_MENU.label) {
        return location.pathname.includes(navItem.route);
      }

      if (navItem.label !== RETAIL_DEALERSHIP_MENU[0].label) {
        return location.pathname.includes(navItem.route);
      }

      if (navItem.label !== WHOLESALE_DEALERSHIP_MENU[0].label) {
        return location.pathname.includes(navItem.route);
      }

      if (
        !location.pathname.includes(RETAIL_DEALERSHIP_MENU[1].route) &&
        !location.pathname.includes(RETAIL_DEALERSHIP_MENU[2].route) &&
        !location.pathname.includes(RETAIL_DEALERSHIP_MENU[3].route) &&
        !location.pathname.includes(WHOLESALE_DEALERSHIP_MENU[1].route) &&
        !location.pathname.includes(WHOLESALE_DEALERSHIP_MENU[2].route) &&
        !location.pathname.includes(WHOLESALE_DEALERSHIP_MENU[3].route) &&
        location.pathname.includes(navItem.route)
      ) {
        return true;
      }

      return false;
    },
    [location]
  );

  const activeDisclosure = useMemo(() => {
    if (location.pathname.includes(DASHBOARD_MENU.route)) {
      return 0;
    }

    if (location.pathname.includes(RETAIL_DEALERSHIP_MENU[0].route)) {
      return 1;
    }

    if (location.pathname.includes(WHOLESALE_DEALERSHIP_MENU[0].route)) {
      return 2;
    }

    if (
      location.pathname.includes(TENDER_MENU.selling.route) ||
      location.pathname.includes(TENDER_MENU.buying.route)
    ) {
      return 3;
    }

    if (
      location.pathname.includes(MOTOR_MARKET_MENU.buying.route) ||
      location.pathname.includes(MOTOR_MARKET_MENU.myListings.route)
    ) {
      return 4;
    }

    return 5;
  }, [location]);

  const navOptions = (navItems: NavItemProps[]) => {
    return navItems.map((navItem) => (
      <NavItem
        key={navItem.route}
        route={navItem.route}
        label={navItem.label}
        icon={navItem.icon}
        isActive={isActive(navItem)}
      />
    ));
  };

  const getTenderMenu = ({ selling, buying }: TenderMenuType) => {
    return [selling, buying].map((option: NavItemProps, index: number) => {
      const hasAccess = !option.permission || hasPermission(option.permission);
      return (
        <NavItem
          key={`${option.route}-${index}`}
          route={hasAccess ? option.route : ACCESS_DENIED_ROUTE}
          label={option.label}
          icon={option.icon}
          isActive={isActive(option)}
          dataTestID={option.dataTestID}
          hasAccess={hasAccess}
        />
      );
    });
  };

  const getMotorMarketMenu = ({ myListings, buying }: MotorMarketMenuType) => {
    return [myListings, buying].map((option: NavItemProps, index: number) => {
      const hasAccess = !option.permission || hasPermission(option.permission);
      return (
        <NavItem
          key={`${option.route}-${index}`}
          route={hasAccess ? option.route : ACCESS_DENIED_ROUTE}
          label={option.label}
          icon={option.icon}
          isActive={isActive(option)}
          dataTestID={option.dataTestID}
          hasAccess={hasAccess}
        />
      );
    });
  };

  const { ref, onMouseUp } = useManageDisclosure([activeDisclosure]);

  const renderMenu = (selectedDisclosureIndex: number) => {
    return (
      <div className="sidebar__menu">
        {hasPermission(PermissionCategory.REPORTS, PermissionAction.VIEW) ? (
          <NavItem
            key={DASHBOARD_MENU.route}
            route={DASHBOARD_MENU.route}
            label={DASHBOARD_MENU.label}
            icon={DASHBOARD_MENU.icon}
            isActive={isActive({
              ...DASHBOARD_MENU,
            })}
          />
        ) : (
          <NoAccessNavigation title={NavigationMenuLabel.DASHBOARD} />
        )}
        {hasPermission(
          PermissionCategory.PPSR_REQUEST,
          PermissionAction.VIEW_ANY
        ) ? (
          <NavItem
            key={PPSR_LIST_MENU.route}
            route={PPSR_LIST_MENU.route}
            label={PPSR_LIST_MENU.label}
            icon={PPSR_LIST_MENU.icon}
            isActive={isActive({
              ...PPSR_LIST_MENU,
            })}
          />
        ) : (
          <NoAccessNavigation title={NavigationMenuLabel.PPSR_CHECK} />
        )}
        {hasPermission(PermissionCategory.RETAIL_INSPECTIONS) ? (
          <Disclosure defaultOpen={selectedDisclosureIndex === 1}>
            {({ open }) => (
              <>
                <Disclosure.Button ref={ref} onMouseUp={onMouseUp}>
                  <NavHeader
                    open={open}
                    label={NavigationMenuLabel.RETAIL_DEALERSHIP}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {navOptions(RETAIL_DEALERSHIP_MENU)}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <NoAccessNavigation title={NavigationMenuLabel.RETAIL_DEALERSHIP} />
        )}
        {hasPermission(PermissionCategory.WHOLESALE_INSPECTIONS) ? (
          <Disclosure defaultOpen={selectedDisclosureIndex === 2}>
            {({ open }) => (
              <>
                <Disclosure.Button ref={ref} onMouseUp={onMouseUp}>
                  <NavHeader
                    open={open}
                    label={NavigationMenuLabel.WHOLESALE_DEALERSHIP}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {navOptions(WHOLESALE_DEALERSHIP_MENU)}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <NoAccessNavigation
            title={NavigationMenuLabel.WHOLESALE_DEALERSHIP}
          />
        )}
        {hasPermission([
          PermissionCategory.TENDER_SELLING,
          PermissionCategory.TENDER_BUYING,
        ]) ? (
          <Disclosure defaultOpen={selectedDisclosureIndex === 3}>
            {({ open }) => (
              <>
                <Disclosure.Button ref={ref} onMouseUp={onMouseUp}>
                  <NavHeader open={open} label={NavigationMenuLabel.TENDER} />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {getTenderMenu(TENDER_MENU)}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <NoAccessNavigation title={NavigationMenuLabel.TENDER} />
        )}
        {hasPermission([
          PermissionCategory.MOTOR_MARKET_SELLING,
          PermissionCategory.MOTOR_MARKET_BUYING,
        ]) ? (
          <Disclosure defaultOpen={selectedDisclosureIndex === 4}>
            {({ open }) => (
              <>
                <Disclosure.Button ref={ref} onMouseUp={onMouseUp}>
                  <NavHeader
                    open={open}
                    label={NavigationMenuLabel.MOTOR_MARKET}
                    dataTestID={NavigationTestID.MotorMarket}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  {getMotorMarketMenu(MOTOR_MARKET_MENU)}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <NoAccessNavigation title={NavigationMenuLabel.MOTOR_MARKET} />
        )}
        {hasPermission(PermissionCategory.DEALERSHIP_POOL) ? (
          <Disclosure defaultOpen={selectedDisclosureIndex === 5}>
            {({ open }) => (
              <>
                <Disclosure.Button ref={ref} onMouseUp={onMouseUp}>
                  <NavHeader open={open} label={NavigationMenuLabel.SETTINGS} />
                </Disclosure.Button>
                <Disclosure.Panel>{navOptions(SETTINGS)}</Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ) : (
          <NoAccessNavigation title={NavigationMenuLabel.SETTINGS} />
        )}
      </div>
    );
  };

  return (
    <aside
      className={`${collapsed ? "sidebar" : "sidebar--collapsed"}`}
      data-testid={dataTestID || theSidebarTestID}
    >
      {activeDisclosure === 0 && renderMenu(0)}
      {activeDisclosure === 1 && renderMenu(1)}
      {activeDisclosure === 2 && renderMenu(2)}
      {activeDisclosure === 3 && renderMenu(3)}
      {activeDisclosure === 4 && renderMenu(4)}
      {activeDisclosure === 5 && renderMenu(5)}
      <div className="sidebar__footer">
        <Link to="/profile">
          <div className="sidebar__menu-item">
            <SvgImage name="AvatarIcon" className="mr-4" />
            <div className="flex flex-col">
              <span>{user?.data.user.name}</span>
              <span className="sidebar__menu-label--secondary">
                {user?.data.dealership?.name}
              </span>
            </div>
          </div>
        </Link>
        <Support />
        <div className="sidebar__menu-item" onClick={logout}>
          <SvgImage name="LogoutIcon" className="mr-4" />
          <span>Log Out</span>
        </div>
        <div className="sidebar__version-num h-8">
          {collapsed && `Version: ${APP_VERSION}`}
        </div>
      </div>
      <ButtonWithIcon
        onClick={onToggleCollapsed}
        className="border-none rounded-full py-2.5 px-3 shadow bg-white absolute -right-4 top-9"
      >
        {collapsed ? (
          <ArrowLeftIcon fill="#0C2146" />
        ) : (
          <ArrowRightIcon fill="#0C2146" />
        )}
      </ButtonWithIcon>
    </aside>
  );
};
