import { useMutation, useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { ChangeEvent, useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { SearchBar } from "components/app";
import { Card } from "components/Card";
import { ConfirmationModal } from "components/Modal/ConfirmationModal";
import { CreateListingModal } from "components/Modal/CreateListingModal";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Button, ButtonLink, Prompt, SvgImage } from "components/shared";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import {
  ActionResult,
  ActionResultMessage,
  convertDayAndTimeToISO,
  createEllipsisText,
  ERROR_MESSAGE,
  MOTOR_MARKET_SOLD,
  showErrorToast,
  showSuccessToast,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import {
  IMotorMarket,
  IMotorMarketForm,
  IMotorMarketGroupVehicle,
} from "types/IMotorMarket";

import { MotorMarketService } from "api/client";

import { useEffectMounted } from "hooks/useEffectMounted";

import { AuctionDetailsForm } from "../../../components/MotorMarket/AuctionDetailsForm/AuctionDetailsForm";

interface StateProps {
  selectedVehicle: IMotorMarket;
}

export const Draft = () => {
  const { userID, hasPermission } = useContext(AuthContext);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { dealershipID, showAllDealerships } = useDealership();
  const selectedVehicle = (state as StateProps)?.selectedVehicle;

  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [status, setStatus] = useState<"draft" | "publish">("draft");
  const [searchText, setSearchText] = useState("");
  const [isCreateListingModalOpen, setIsCreateListingModalOpen] =
    useState(false);

  const [
    isRemoveVehicleConfirmationModalOpen,
    setIsRemoveVehicleConfirmationModalOpen,
  ] = useState(false);
  const params = useParams();

  const [currentClickedVehicle, setCurrentClickedVehicle] = useState<
    IMotorMarketGroupVehicle | undefined
  >(undefined);

  const canView = hasPermission(
    PermissionCategory.MOTOR_MARKET_SELLING,
    PermissionAction.VIEW
  );

  const canUpdate = hasPermission(
    PermissionCategory.MOTOR_MARKET_SELLING,
    PermissionAction.UPDATE
  );

  const {
    data,
    isInitialLoading: loadingAuction,
    isError: isGetAuctionError,
    refetch: refetchAuction,
  } = useQuery<{
    data: IMotorMarket;
  }>(
    [`motor-market-seller-summary-draft-${userID}`, params.id],
    MotorMarketService.getGroupDetails,
    {
      enabled: canView,
    }
  );

  const { mutate: updateGroup, isLoading: updatingGroup } = useMutation(
    (payload: IMotorMarketForm) =>
      MotorMarketService.updateGroup(
        data?.data.auction_group_id || "",
        status,
        payload
      ),
    {
      onSuccess: () => {
        if (status === "publish") {
          showSuccessToast("Your Listing is Live");
          setIsPublishModalOpen(false);
        } else {
          showSuccessToast("Draft Saved");
        }
        reset({}, { keepDirty: false, keepValues: true });
        navigate("/motor-market/my-listings");
      },
      onError: () => {
        showErrorToast(
          status === "publish" ? "Failed to Publish" : "Failed to Save"
        );
      },
    }
  );

  const { mutate: removeVehicle, isLoading: isRemovingVehicle } = useMutation(
    (id: string) => MotorMarketService.deleteVehicle(id),
    {
      onSuccess: () => {
        showSuccessToast("Vehicle Removed");
        setIsRemoveVehicleConfirmationModalOpen(false);
        setCurrentClickedVehicle(undefined);
        refetchAuction();
      },
      onError: () => {
        showErrorToast("Failed to Remove");
        setIsRemoveVehicleConfirmationModalOpen(false);
        setCurrentClickedVehicle(undefined);
      },
    }
  );

  const {
    register,
    setValue,
    handleSubmit,
    setFocus,
    control,
    formState,
    getValues,
    reset,
    setError,
  } = useForm<IMotorMarketForm>();

  const validateEndDate = (formData: IMotorMarketForm) => {
    const { ends_at, ends_at_time } = formData;
    if (new Date(`${ends_at} ${ends_at_time}`) < new Date()) {
      setError("ends_at", { type: "validate" });
      return false;
    }

    return true;
  };

  const onPublish = (data: IMotorMarketForm) => {
    if (!validateEndDate(data)) return;
    setStatus("publish");
    updateForm(data);
  };

  const onSaveDraft = (data: IMotorMarketForm) => {
    if (!validateEndDate(data)) return;
    setStatus("draft");
    updateForm(data);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filteredVehicles = useMemo(() => {
    const vehicles = data?.data?.vehicles;
    if (!vehicles) return [];
    if (!searchText) return vehicles;

    return vehicles?.filter((vehicle) =>
      vehicle.vehicle.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [data?.data?.vehicles, searchText]);

  const updateForm = (data: IMotorMarketForm) => {
    const isoDateTime = convertDayAndTimeToISO(
      data.ends_at,
      data.ends_at_time || ""
    );

    updateGroup({
      contact_name: data.contact_name,
      contact_phone: data.contact_phone,
      location: data.location,
      ends_at: isoDateTime,
    });
  };

  useEffectMounted(() => {
    if (!showAllDealerships) {
      navigate(`/motor-market/my-listings`);
    }
  }, [dealershipID]);

  if (!canView)
    return (
      <NoPermissionMessage
        viewPermissionCategory={PermissionCategory.MOTOR_MARKET_SELLING}
      />
    );

  if (loadingAuction) return <span>Loading...</span>;

  if (isGetAuctionError) {
    return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitle("My Listings", SECTIONS.MOTORMARKET)}</title>
      </Helmet>
      <div className="flex flex-col p-7 w-full h-full overflow-auto relative">
        <div className="flex items-center justify-between w-full">
          <div className="text-2xl text-txt font-semibold">My Listings</div>
          <div
            className={classNames("flex flex-row items-center gap-4", {
              "w-[160px]": filteredVehicles?.length === 0,
              "w-[320px]": filteredVehicles?.length > 0,
            })}
          >
            <div className="flex-1">
              {canUpdate && (
                <Button
                  type="button"
                  variant="secondary"
                  className="border"
                  onClick={handleSubmit(onSaveDraft)}
                  isLoading={updatingGroup}
                >
                  <b>SAVE DRAFT</b>
                </Button>
              )}
            </div>
            {filteredVehicles?.length > 0 && canUpdate && (
              <div className="flex-1">
                <Button
                  type="button"
                  isLoading={updatingGroup}
                  onClick={() => setIsPublishModalOpen(true)}
                >
                  <b>PUBLISH</b>
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="flex mt-4 space-x-3 border-b border-[#EFEFEF]">
          <div className="relative w-full sm:max-w-[433px] sm:w-[80%] min-w-[433px] overflow-hidden mt-11">
            {data?.data.seller.logo_url ? (
              <img
                src={data?.data.seller.logo_url}
                alt="logo"
                className="object-contain h-[250px] rounded-md"
              />
            ) : (
              <SvgImage
                name="NoImageSvg"
                className="object-contain h-[250px] rounded-md"
              />
            )}
          </div>
          <AuctionDetailsForm
            register={register}
            setValue={setValue}
            formState={formState}
            setFocus={setFocus}
            control={control}
            isLoading={loadingAuction}
            getValues={getValues}
            data={{
              contact_name:
                selectedVehicle?.contact.name ||
                data?.data?.contact?.name ||
                "",
              contact_phone:
                selectedVehicle?.contact.phone ||
                data?.data.contact?.phone ||
                "",
              location:
                selectedVehicle?.contact.location ||
                data?.data.contact.location ||
                "TBD",
              ends_at: selectedVehicle?.ends_at || data?.data.ends_at || "",
            }}
            canEdit={canUpdate}
          />
        </div>
        <div className="w-full flex justify-between mt-8">
          <div className="flex-col space-y-2">
            <p className="text-2xl text-txt font-semibold">Vehicles</p>
            <p className="text-label text-sm font-normal">
              {`${filteredVehicles.length} Vehicles in this listing`}
            </p>
          </div>
          <div className="flex items-center gap-2">
            <div className="font-bold flex gap-5">
              {canUpdate && (
                <ButtonLink onClick={() => setIsCreateListingModalOpen(true)}>
                  <span className="underline underline-offset-2 text-secondary text-base">
                    Add Vehicles
                  </span>
                </ButtonLink>
              )}
              <div className="w-[450px] relative font-normal">
                <SearchBar onSearch={onSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-7 py-7">
          {filteredVehicles.map((vehicle) => (
            <Card
              key={vehicle.auction_vehicle_id}
              coverSource={vehicle.images?.[0]?.url || ""}
              content={[
                <div
                  className="flex flex-row gap-5"
                  key={vehicle.auction_vehicle_id}
                >
                  <div className="flex flex-col gap-2.5 max-w-md">
                    <p className="text-base">
                      <span className="font-semibold">
                        {vehicle.vehicle.name}
                      </span>
                    </p>
                    <p className="text-sm">
                      {"Vehicle ID: "}
                      <span className="font-semibold">
                        {vehicle.vehicle.compliance.vin}
                      </span>
                    </p>
                    <div className="flex items-center">
                      <p className="text-sm text-label ">
                        {createEllipsisText(
                          Object.values(vehicle.vehicle.specification)
                            .filter(
                              (value) =>
                                !!value &&
                                !vehicle.vehicle.name.includes(
                                  value as unknown as string
                                )
                            )
                            .join(" | "),
                          80
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5 max-w-sm">
                    <p className="text-sm">
                      {"Acquisition Price: "}
                      <span className="font-semibold">{"-"}</span>
                    </p>
                    <p className="text-sm">
                      {"Bids: "}
                      <span className="font-semibold">{"-"}</span>
                    </p>
                    <p className="text-sm">
                      {"Highest Bid: "}
                      <span className="font-semibold">{"-"}</span>
                    </p>
                    <p className="text-sm">
                      {"Bidder: "}
                      <span className="font-semibold">{"-"}</span>
                    </p>
                  </div>
                </div>,
              ]}
              status={
                vehicle.status.name === MOTOR_MARKET_SOLD.key
                  ? {
                      status: MOTOR_MARKET_SOLD.key,
                      label: MOTOR_MARKET_SOLD.label,
                      amount: 0,
                    }
                  : undefined
              }
              actions={[
                vehicle.status.name !== MOTOR_MARKET_SOLD.key && canUpdate && (
                  <button
                    key={"delete-card-button"}
                    className="underline text-darkRed"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentClickedVehicle(vehicle);
                      setIsRemoveVehicleConfirmationModalOpen(true);
                    }}
                  >
                    <p className="text-sm text-darkRed">Remove Vehicle</p>
                  </button>
                ),
              ]}
              onClick={() => {
                navigate(
                  `/motor-market/my-listings/${vehicle.contract_id}/summary`,
                  { state: { draftVehicle: vehicle } }
                );
              }}
            />
          ))}
        </div>
      </div>
      <CreateListingModal
        isOpen={isCreateListingModalOpen}
        onClose={() => {
          setIsCreateListingModalOpen(false);
          setStatus("draft");
        }}
        step="Vehicles"
        sellerID={data?.data.seller.id}
        currentAuctionID={data?.data.auction_group_id}
        refetch={refetchAuction}
        setIsCreateListingModalOpen={setIsCreateListingModalOpen}
      />
      <ConfirmationModal
        title={
          ActionResultMessage[ActionResult.RemoveMotorMarketDraftVehicle].title
        }
        description={
          ActionResultMessage[ActionResult.RemoveMotorMarketDraftVehicle]
            .message
        }
        isOpen={isRemoveVehicleConfirmationModalOpen}
        onClose={() => setIsRemoveVehicleConfirmationModalOpen(false)}
        onConfirm={() =>
          removeVehicle(currentClickedVehicle?.auction_vehicle_id || "")
        }
        isLoading={isRemovingVehicle}
        confirmButtonLabel="YES"
        cancelButtonLabel="NO"
      />
      <ConfirmationModal
        title={
          ActionResultMessage[ActionResult.PublishMotorMarketListing].title
        }
        description={
          ActionResultMessage[ActionResult.PublishMotorMarketListing].message
        }
        isOpen={isPublishModalOpen}
        onClose={() => setIsPublishModalOpen(false)}
        onConfirm={handleSubmit(onPublish)}
        isLoading={updatingGroup}
      />
      <Prompt isDirty={formState.isDirty} />
    </>
  );
};
