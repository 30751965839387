import { useState } from "react";
import { useForm } from "react-hook-form";

import "../styles.scss";
import { modalOfferNegotiationTestID } from "components/app/dataTestIDs";
import {
  Button,
  FieldText,
  FieldTextarea,
  SvgImage,
  TextError,
} from "components/shared";

import { checkIsValidNumber, formatNumber } from "utils";

export interface ModalOfferNegotiationProps {
  onCloseModal: () => void;
  onSubmit: (data: INegotiationOffer) => void;
  isSubmitting: boolean;
  dataTestID?: string;
  isAccepted?: boolean;
  initialOfferAmount?: string;
}

export interface INegotiationOffer {
  amount: string;
  comment: string;
}

export const ModalOfferNegotiation = ({
  onCloseModal,
  onSubmit,
  isSubmitting,
  dataTestID,
  isAccepted,
  initialOfferAmount,
}: ModalOfferNegotiationProps) => {
  const [isNumber, setIsNumber] = useState(true);
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<INegotiationOffer>({
    defaultValues: {
      amount: initialOfferAmount || "",
      comment: "",
    },
  });

  const handleOffer = (value: string) => {
    setValue("amount", formatNumber(value.replaceAll(",", "")));
    setIsNumber(checkIsValidNumber(getValues("amount")));
    clearErrors("amount");
  };

  return (
    <div
      className="offer-negotiation"
      data-testid={dataTestID || modalOfferNegotiationTestID}
    >
      <div className="offer-negotiation__txt">
        <FieldText
          title={isAccepted ? "Acquisition Price*" : "Offer Amount*"}
          type="text"
          {...register("amount", { required: true })}
          placeholder="Enter offer amount"
          onChange={(e) => handleOffer(e.target.value)}
        />
        <span className="absolute top-8 right-3">
          <SvgImage name="EditPen" />
        </span>
        <div>
          {errors.amount && <TextError error="Offer Amount is required" />}
        </div>
        <div>
          {isNumber === false && (
            <TextError error="Please enter numbers only" />
          )}
        </div>
        <span className="offer-negotiation__txt__unit">$</span>
      </div>
      {isAccepted && (
        <div className="mt-6">
          <FieldTextarea
            title="Comments"
            type="text"
            {...register("comment", { required: false })}
            placeholder="Enter comments here"
          />
        </div>
      )}
      <div className="offer-negotiation__footer">
        <div className="offer-negotiation__tool--cancel">
          <Button type="button" onClick={onCloseModal}>
            <span>cancel</span>
          </Button>
        </div>

        <div className="offer-negotiation__tool">
          <Button
            type="submit"
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            disabled={!isNumber}
          >
            <span className="offer-negotiation__tool__title">CONFIRM</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
