import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { ClipLoader } from "react-spinners";

import { LoadItem } from "components/app";
import { NotesModal } from "components/Modal/NotesModal";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { MAX_CHARACTERS_NOTES, showErrorToast } from "utils";

import { VehicleService } from "api/client";

import { Note } from "./Note/Note";

interface VehicleNotesProps {
  contractID: string;
  canComment?: boolean;
}

export const VehicleNotes = ({
  contractID,
  canComment = true,
}: VehicleNotesProps) => {
  const { userID } = useContext(AuthContext);
  const { dealershipID, showAllDealerships } = useDealership();

  const [note, setNote] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasCharacterLimit, setHasCharacterLimit] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    [`fetch_customer_notes_${contractID}_${userID}`, contractID, dealershipID],
    VehicleService.getVehicleComments
  );

  const { mutate: addNote, isLoading: isLoadingAddNote } = useMutation(
    (latestNote: string) =>
      VehicleService.addComment(
        {
          commentable_id: contractID,
          commentable_type: "Contract",
          comment: latestNote,
          dealership_id: !showAllDealerships ? dealershipID : undefined,
        },
        contractID
      ),
    {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        showErrorToast("Failed to Save");
      },
    }
  );

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (note === "" || hasCharacterLimit) return;

      addNote(note);
      setNote("");
    }
  };

  const latestNote = data?.data?.[0] || null;

  return (
    <div className="rounded-xl w-full h-fit p-6 flex flex-col gap-4 shadow-sm bg-sidebarbg">
      <div className="flex items-center justify-between">
        <div className="flex">
          <span className="font-semibold text-lightGrey text-base">Notes</span>
          {isLoadingAddNote && (
            <div className="flex h-full items-center justify-center ml-3">
              <ClipLoader color="#0c2146" size={20} />
            </div>
          )}
        </div>
        {!!data?.data?.length && (
          <span
            className="text-secondary font-medium text-sm cursor-pointer"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >{`View All >`}</span>
        )}
      </div>
      <div>
        <textarea
          className="text-left w-full rounded-4 border-solid border-grey border pl-4 p-2 resize-none overflow-hidden focus:outline-none"
          placeholder="Add your notes here. These are private and will only be visible to your dealership."
          value={note}
          onChange={(e) => {
            const target = e.target;

            target.style.height = "auto";
            target.style.height = `${target.scrollHeight}px`;

            if (target.value.length > MAX_CHARACTERS_NOTES)
              setHasCharacterLimit(true);
            else {
              setHasCharacterLimit(false);
              setNote(target.value);
            }
          }}
          disabled={!canComment}
          onKeyDown={onKeyDown}
          rows={2}
        />
        {hasCharacterLimit && (
          <span className="text-darkRed font-normal text-sm">
            Character limit reached.
          </span>
        )}
      </div>
      {isLoading && <LoadItem />}
      {latestNote && <Note note={latestNote} showFullNote={false} />}
      <NotesModal
        data={data?.data || []}
        isLoading={isLoadingAddNote}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onSave={(note: string) => {
          addNote(note);
        }}
        canComment={canComment}
      />
    </div>
  );
};
