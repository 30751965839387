import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Ably from "ably";
import { Helmet } from "react-helmet";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthorizedRoute } from "routes";

import {
  AccessDenied,
  AcquisitionList,
  AcquisitionSummary,
  Complete,
  Compliance,
  Condition,
  CustomerConditions,
  CustomerDetails,
  CustomerFeaturesAndOptions,
  CustomerList,
  CustomerPictures,
  CustomerSpecification,
  EnterKMS,
  Features,
  FindVehicle,
  FindVehicleByVin,
  ForgotPassword,
  Login,
  BuyerSummary as MotorMarketBuyerSummary,
  Buying as MotorMarketBuying,
  Draft as MotorMarketDraft,
  MyListings as MotorMarketMyListings,
  Summary as MotorMarketSummary,
  NegotiationList,
  NegotiationSummary,
  ResetPassword,
  Specification,
  TenderBuyerVehicleSummary,
  TenderBuyingList,
  TenderBuyingSummary,
  TenderSellerVehicleSummary,
  TenderSellingList,
  TenderSellingSummary,
  ValuationList,
  ValuationSummary,
  VehicleConfirmation,
  Welcome,
  WholesaleAcquisitionList,
  WholesaleAcquisitionSummary,
  WholesaleCustomerList,
  WholesaleDealershipList,
  WholesaleDealershipSummary,
  WholesaleNegotiationList,
  WholesaleNegotiationSummary,
} from "pages";
import { Dashboard } from "pages/Dashboard";
import { PPSRList } from "pages/PPSRList";
import { Profile } from "pages/Profile";
import { SearchList } from "pages/Search/List";
import { SearchSummary } from "pages/Search/Summary";
import { DealershipPool } from "pages/Settings";

import { LayoutCustomer, LayoutDashboard } from "components/layouts";
import { NotificationProvider } from "components/NotificationProvider";
import { ButtonLink, SvgImage } from "components/shared";

import { AuthProvider } from "context/AuthContext";
import DealershipProvider from "context/DealershipContext";
import SearchProvider from "context/SearchContext";

import "react-toastify/dist/ReactToastify.css";
import "./assets/css/app.scss";

import { FEATURES, FLOW_TYPE, isFeatureAvailable, VEHICLE_ROUTES } from "utils";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const notificationClient = new Ably.Realtime(
  process.env.REACT_APP_ABLY_KEY || ""
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT,
  debug: false,
  enabled: ["prod", "uat"].some(
    (env) => env === process.env.REACT_APP_ENVIRONMENT
  ),

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const CloseToast = ({ closeToast }: { closeToast?: () => void }) => {
  return (
    <ButtonLink onClick={closeToast!}>
      <SvgImage name="ToastClose" />
    </ButtonLink>
  );
};

function App() {
  const vehicleInformationRoutes = (flow: FLOW_TYPE, route: VEHICLE_ROUTES) => {
    return (
      <>
        <Route
          path=":id/specification"
          element={
            <AuthorizedRoute>
              <Specification flow={flow} route={route} />
            </AuthorizedRoute>
          }
        />
        <Route
          path=":id/features"
          element={
            <AuthorizedRoute>
              <Features flow={flow} route={route} />
            </AuthorizedRoute>
          }
        />
        <Route
          path=":id/condition"
          element={
            <AuthorizedRoute>
              <Condition flow={flow} route={route} />
            </AuthorizedRoute>
          }
        />
        <Route
          path=":id/compliance"
          element={
            <AuthorizedRoute>
              <Compliance flow={flow} route={route} />
            </AuthorizedRoute>
          }
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>MotorPlatform</title>
        <meta
          name="description"
          content="Introducing the world's first end-to-end vehicle valuation platform. Buy and sell your vehicles with ease, wherever you are."
        />
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <Router>
          <DealershipProvider>
            <AuthProvider>
              <NotificationProvider client={notificationClient}>
                <SearchProvider>
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/reset/:token/:email"
                      element={<ResetPassword />}
                    />
                    {isFeatureAvailable(FEATURES.DASHBOARD) && (
                      <Route path="/dashboard" element={<LayoutDashboard />}>
                        <Route
                          index
                          element={
                            <AuthorizedRoute>
                              <Dashboard />
                            </AuthorizedRoute>
                          }
                        />
                      </Route>
                    )}
                    <Route path="/ppsr-list" element={<LayoutDashboard />}>
                      <Route
                        index
                        element={
                          <AuthorizedRoute>
                            <PPSRList />
                          </AuthorizedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="/retail-dealership"
                      element={<LayoutDashboard />}
                    >
                      <Route
                        index
                        element={
                          <AuthorizedRoute>
                            <ValuationList />
                          </AuthorizedRoute>
                        }
                      />
                      <Route
                        path="valuation/:id/summary"
                        element={
                          <AuthorizedRoute>
                            <ValuationSummary
                              flow={FLOW_TYPE.RETAIL_DEALERSHIP}
                            />
                          </AuthorizedRoute>
                        }
                      />
                      {vehicleInformationRoutes(
                        FLOW_TYPE.RETAIL_DEALERSHIP,
                        VEHICLE_ROUTES.Unvalued
                      )}
                      <Route path="valued">
                        <Route
                          index
                          element={
                            <AuthorizedRoute>
                              <NegotiationList />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path=":id/summary"
                          element={
                            <AuthorizedRoute>
                              <NegotiationSummary
                                flow={FLOW_TYPE.RETAIL_DEALERSHIP}
                              />
                            </AuthorizedRoute>
                          }
                        />
                        {vehicleInformationRoutes(
                          FLOW_TYPE.RETAIL_DEALERSHIP,
                          VEHICLE_ROUTES.Valued
                        )}
                      </Route>
                      <Route path="stocklist">
                        <Route
                          index
                          element={
                            <AuthorizedRoute>
                              <AcquisitionList />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path=":id/summary"
                          element={
                            <AuthorizedRoute>
                              <AcquisitionSummary
                                flow={FLOW_TYPE.RETAIL_DEALERSHIP}
                              />
                            </AuthorizedRoute>
                          }
                        />
                        {vehicleInformationRoutes(
                          FLOW_TYPE.RETAIL_DEALERSHIP,
                          VEHICLE_ROUTES.Stocklist
                        )}
                      </Route>
                      <Route path="customers">
                        <Route
                          index
                          element={
                            <AuthorizedRoute>
                              <CustomerList />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="information/:contract_id"
                          element={
                            <AuthorizedRoute>
                              <CustomerDetails />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path=":id/information"
                          element={
                            <AuthorizedRoute>
                              <CustomerDetails />
                            </AuthorizedRoute>
                          }
                        />
                      </Route>
                    </Route>
                    <Route
                      path="/wholesale-dealership"
                      element={<LayoutDashboard />}
                    >
                      <Route
                        index
                        element={
                          <AuthorizedRoute>
                            <WholesaleDealershipList />
                          </AuthorizedRoute>
                        }
                      />
                      <Route
                        path="valuation/:id/:requestId/summary"
                        element={
                          <AuthorizedRoute>
                            <WholesaleDealershipSummary />
                          </AuthorizedRoute>
                        }
                      />
                      <Route
                        path={"valuation/:id/summary"}
                        element={
                          <AuthorizedRoute>
                            <ValuationSummary
                              flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                            />
                          </AuthorizedRoute>
                        }
                      />
                      {vehicleInformationRoutes(
                        FLOW_TYPE.WHOLESALE_DEALERSHIP,
                        VEHICLE_ROUTES.Unvalued
                      )}
                      <Route path="valued">
                        <Route
                          index
                          element={
                            <AuthorizedRoute>
                              <WholesaleNegotiationList />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path=":id/:requestId/summary"
                          element={
                            <AuthorizedRoute>
                              <WholesaleNegotiationSummary />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path=":id/summary"
                          element={
                            <AuthorizedRoute>
                              <NegotiationSummary
                                flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                              />
                            </AuthorizedRoute>
                          }
                        />
                        {vehicleInformationRoutes(
                          FLOW_TYPE.WHOLESALE_DEALERSHIP,
                          VEHICLE_ROUTES.Valued
                        )}
                      </Route>
                      <Route path="stocklist">
                        <Route
                          index
                          element={
                            <AuthorizedRoute>
                              <WholesaleAcquisitionList />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path=":id/:requestId/summary"
                          element={
                            <AuthorizedRoute>
                              <WholesaleAcquisitionSummary />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path=":id/summary"
                          element={
                            <AuthorizedRoute>
                              <AcquisitionSummary
                                flow={FLOW_TYPE.WHOLESALE_DEALERSHIP}
                              />
                            </AuthorizedRoute>
                          }
                        />
                        {vehicleInformationRoutes(
                          FLOW_TYPE.WHOLESALE_DEALERSHIP,
                          VEHICLE_ROUTES.Stocklist
                        )}
                      </Route>
                      <Route
                        path="clients"
                        element={<WholesaleCustomerList />}
                      />
                      <Route path="clients">
                        <Route
                          path=":id/information"
                          element={
                            <AuthorizedRoute>
                              <CustomerDetails />
                            </AuthorizedRoute>
                          }
                        />
                        <Route
                          path="information/:contract_id"
                          element={
                            <AuthorizedRoute>
                              <CustomerDetails />
                            </AuthorizedRoute>
                          }
                        />
                      </Route>
                    </Route>
                    {
                      <Route path="/tender" element={<LayoutDashboard />}>
                        <Route path="selling">
                          <Route
                            index
                            element={
                              <AuthorizedRoute>
                                <TenderSellingList />
                              </AuthorizedRoute>
                            }
                          />
                          <Route
                            path=":id/summary"
                            element={
                              <AuthorizedRoute>
                                <TenderSellingSummary />
                              </AuthorizedRoute>
                            }
                          />
                          <Route
                            path=":tenderId/:vehicleId/summary"
                            element={
                              <AuthorizedRoute>
                                <TenderSellerVehicleSummary />
                              </AuthorizedRoute>
                            }
                          />
                        </Route>
                        <Route path="buying">
                          <Route
                            index
                            element={
                              <AuthorizedRoute>
                                <TenderBuyingList />
                              </AuthorizedRoute>
                            }
                          />
                          <Route
                            path=":id/summary"
                            element={
                              <AuthorizedRoute>
                                <TenderBuyingSummary />
                              </AuthorizedRoute>
                            }
                          />
                          <Route
                            path=":tenderId/:vehicleId/summary"
                            element={
                              <AuthorizedRoute>
                                <TenderBuyerVehicleSummary />
                              </AuthorizedRoute>
                            }
                          />
                        </Route>
                      </Route>
                    }
                    {
                      <Route path="/motor-market" element={<LayoutDashboard />}>
                        <Route path="my-listings">
                          <Route
                            index
                            element={
                              <AuthorizedRoute>
                                <MotorMarketMyListings />
                              </AuthorizedRoute>
                            }
                          />
                          <Route
                            path=":id/draft"
                            element={
                              <AuthorizedRoute>
                                <MotorMarketDraft />
                              </AuthorizedRoute>
                            }
                          />
                          <Route
                            path=":id/summary"
                            element={
                              <AuthorizedRoute>
                                <MotorMarketSummary />
                              </AuthorizedRoute>
                            }
                          />
                        </Route>
                        <Route path="buying">
                          <Route
                            index
                            element={
                              <AuthorizedRoute>
                                <MotorMarketBuying />
                              </AuthorizedRoute>
                            }
                          />
                          <Route
                            path=":id/summary"
                            element={
                              <AuthorizedRoute>
                                <MotorMarketBuyerSummary />
                              </AuthorizedRoute>
                            }
                          />
                        </Route>
                      </Route>
                    }
                    <Route path="/settings" element={<LayoutDashboard />}>
                      <Route
                        index
                        element={
                          <AuthorizedRoute>
                            <DealershipPool />
                          </AuthorizedRoute>
                        }
                      />
                      <Route
                        path="dealership-pool"
                        element={
                          <AuthorizedRoute>
                            <DealershipPool />
                          </AuthorizedRoute>
                        }
                      />
                    </Route>
                    <Route path="/profile" element={<LayoutDashboard />}>
                      <Route
                        index
                        element={
                          <AuthorizedRoute>
                            <Profile />
                          </AuthorizedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="/inspection/customer/:id"
                      element={<LayoutCustomer />}
                    >
                      <Route path="start" element={<Welcome />} />
                      <Route path="find-vehicle" element={<FindVehicle />} />
                      <Route
                        path="find-vehicle-vin"
                        element={<FindVehicleByVin />}
                      />
                      <Route
                        path="vehicle-confirmation"
                        element={<VehicleConfirmation />}
                      />
                      <Route path="enter-kms" element={<EnterKMS />} />
                      <Route
                        path="specification"
                        element={<CustomerSpecification />}
                      />
                      <Route
                        path="features-and-options"
                        element={<CustomerFeaturesAndOptions />}
                      />
                      <Route
                        path="conditions"
                        element={<CustomerConditions />}
                      />
                      <Route path="picture" element={<CustomerPictures />} />
                      <Route path="complete" element={<Complete />} />
                    </Route>
                    <Route path="/access-denied" element={<LayoutDashboard />}>
                      <Route
                        index
                        element={
                          <AuthorizedRoute>
                            <AccessDenied />
                          </AuthorizedRoute>
                        }
                      />
                    </Route>
                    <Route path="/search" element={<LayoutDashboard />}>
                      <Route
                        index
                        element={
                          <AuthorizedRoute>
                            <SearchList />
                          </AuthorizedRoute>
                        }
                      />
                      <Route
                        path="summary/:id"
                        element={
                          <AuthorizedRoute>
                            <SearchSummary />
                          </AuthorizedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="*"
                      element={
                        <AuthorizedRoute>
                          <LayoutDashboard />
                        </AuthorizedRoute>
                      }
                    ></Route>
                  </Routes>
                  <ToastContainer
                    hideProgressBar={true}
                    icon={false}
                    closeButton={CloseToast}
                  />
                </SearchProvider>
              </NotificationProvider>
            </AuthProvider>
          </DealershipProvider>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default App;
