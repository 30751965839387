import { Combobox } from "@headlessui/react";
import classNames from "classnames";

import { SvgImage } from "components/shared/Image";

import { IOption } from "types";

type ComboboxOptionProps = {
  option: IOption;
  value: string;
  dropdownGreyBG?: boolean;
  noIndent?: boolean;
  parent?: string;
};

export const ComboboxOption = ({
  option,
  value,
  dropdownGreyBG,
  parent,
  noIndent,
}: ComboboxOptionProps) => {
  return (
    <Combobox.Option
      key={option.key}
      className={classNames("dropdown__option", {
        "hover:bg-white": dropdownGreyBG,
        "ml-3": !noIndent && !parent,
        "ml-6": !!parent,
      })}
      value={option.key}
      hidden={option.isHidden}
    >
      {({ active }: { selected: boolean; active: boolean }) => (
        <div
          className={classNames("dropdown__option__container", {
            "dropdown__option__container--selected": value.includes(
              option.key as string
            ),
          })}
        >
          <span className="dropdown__option__title !h-auto">
            {option.label}
          </span>
          {value.includes(option.key as string) ? (
            <span className="dropdown__option__icon">
              <SvgImage name="CheckIcon" />
            </span>
          ) : null}
        </div>
      )}
    </Combobox.Option>
  );
};
