import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  type DragEndEvent,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
} from "@tanstack/react-table";
import React, { useState } from "react";

import { LoadVehicle } from "components/app";

import { PPSRCheckInfo } from "types";

import useLocalStorage from "hooks/useLocalStorage";

import { Columns } from "./Columns";
import { DraggableTableHeader } from "./DraggableHeader";

type PPSRTableProps = {
  refetch: () => void;
  data: PPSRCheckInfo[];
  isInitialLoading: boolean;
};

export const PPSRCheckTable = ({
  data,
  refetch,
  isInitialLoading,
}: PPSRTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const onRefresh = () => {
    refetch();
  };

  const { columns } = Columns({ onRefresh });

  const [columnOrder, setColumnOrder] = useLocalStorage(
    "columnOrder",
    columns.map((c) => c.id!)
  );

  const orderedColumns = React.useMemo(
    () =>
      columnOrder
        .map((colId: string | undefined) =>
          columns.find((col) => col.id === colId)
        )
        .filter((col: any): col is ColumnDef<PPSRCheckInfo> => !!col),
    [columnOrder, columns]
  );

  const table = useReactTable({
    data,
    columns: orderedColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: { sorting },
    onSortingChange: setSorting,
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((prevOrder: string[]) => {
        const oldIndex = prevOrder.indexOf(active.id as string);
        const newIndex = prevOrder.indexOf(over.id as string);
        return arrayMove(prevOrder, oldIndex, newIndex);
      });
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  if (isInitialLoading) {
    return <LoadVehicle />;
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <table className="min-w-full border-collapse border-[#EFEFEF] my-4">
        <thead>
          {table?.getHeaderGroups()?.map((headerGroup) => (
            <tr key={headerGroup.id}>
              <SortableContext
                items={columnOrder}
                strategy={horizontalListSortingStrategy}
              >
                {headerGroup.headers.map((header) => (
                  <DraggableTableHeader
                    key={header.id}
                    header={header}
                    onSort={(id) =>
                      setSorting((prev) =>
                        prev[0]?.id === id
                          ? [{ id, desc: !prev[0].desc }]
                          : [{ id, desc: false }]
                      )
                    }
                    isSorted={sorting[0]?.id === header.id}
                    isDesc={sorting[0]?.id === header.id && sorting[0]?.desc}
                  />
                ))}
              </SortableContext>
            </tr>
          ))}
        </thead>
        <tbody>
          {table?.getRowModel().rows?.map((row) => (
            <tr key={row.id} className="even:bg-gray-50">
              {row.getVisibleCells().map((cell) => {
                const isHighlightedColumn = [
                  "is_stolen",
                  "is_written_off",
                  "has_security_interest",
                ].includes(cell.column.id);
                const isHighlighted = isHighlightedColumn && !!cell.getValue();
                const isUnknown =
                  cell.row.original.name?.toLowerCase() === "unknown";

                return (
                  <td
                    key={cell.id}
                    className={`p-1 border border-[#EFEFEF] text-sm align-middle ${
                      isHighlighted && !isUnknown
                        ? "bg-[#FFE7E5]"
                        : "text-gray-800"
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </DndContext>
  );
};
