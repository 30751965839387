import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PPSRStatus } from "enums/PPSRStatus";
import { useContext } from "react";

import { AuthContext } from "context/AuthContext";
import { useDealership } from "context/DealershipContext";

import { showErrorToast, showSuccessToast, StorageKeys } from "utils";

import { PPSRCheckInfo } from "types";

import { PPSRService, VehicleService } from "api/client";

type UseSearchByVinOrContractProps = {
  onRefetch?: () => void;
  contractID?: string;
  isRefresh?: boolean;
};

export const useSearchByVinOrContract = ({
  onRefetch,
  isRefresh,
}: UseSearchByVinOrContractProps) => {
  const { dealershipID, showAllDealerships } = useDealership();
  const { user } = useContext(AuthContext);
  const parentDealership = user?.data.dealership?.id || "";
  const queryClient = useQueryClient();

  const onSuccess = (data?: PPSRCheckInfo) => {
    queryClient.invalidateQueries([StorageKeys.PPSRList]);

    showSuccessToast(
      data?.api_status !== PPSRStatus.Changed && !isRefresh
        ? "Saved Successfully"
        : "No Changes Recorded"
    );

    onRefetch?.();
  };

  const { mutate: searchContractPPSR, isLoading: isLoadingSearchPPSR } =
    useMutation(
      ({ contractID, refetch }: { contractID: string; refetch: boolean }) =>
        VehicleService.searchPPSR(contractID, refetch),
      {
        onSuccess: () => {
          onSuccess();
        },
        onError: () => {
          showErrorToast("Something went wrong. Try Again ");
        },
      }
    );

  const { mutate: searchByVin, isLoading: isLoadingByVin } = useMutation(
    ({ vin, refetch }: { vin: string; refetch: boolean }) =>
      PPSRService.request({
        vin,
        refetch,
        dealership_id: showAllDealerships
          ? parentDealership
          : dealershipID || "",
      }),
    {
      onSuccess: () => {
        onSuccess();
      },
      onError: () => {
        showErrorToast("Something went wrong. Try again ");
      },
    }
  );

  return {
    searchByVin,
    isLoadingByVin,
    searchByContractID: searchContractPPSR,
    isLoadingSearchPPSR,
  };
};
