import { useQuery } from "@tanstack/react-query";
import { useContext, useMemo, useState } from "react";

import { BidsHistory } from "pages/MotorMarket/Summary/BidsHistory";

import {
  FieldMotorIntelValuation,
  FieldState,
  FieldValuation,
  FieldValuationWholeRow,
  ModalInternalValuation,
  ModalMotorIntelLog,
  ModalOfferLog,
  ModalWholesaleValuations,
  TenderBidSection,
} from "components/app";
import { ModalAddNotes } from "components/app/Modal";
import { DirectOfferBuyLog } from "components/DirectOffer/DirectOfferBuyLog";
import { DirectOfferDetails } from "components/DirectOffer/DirectOfferDetails";
import { ModalMotorMarketBidsHistory } from "components/Modal/MotorMarketBids";
import { PricingInsightsModal } from "components/Modal/PricingInsightsModal";
import { ButtonLink, Modal, Tab } from "components/shared";
import { ITabItem } from "components/shared/Tab/Tab";

import { AuthContext } from "context/AuthContext";

import {
  ACQUIRED,
  BUYING,
  FLOW_TYPE,
  formatNumber,
  MOTOR_MARKET_AUCTION,
  MOTOR_MARKET_SOLD_AUCTION,
  SOLDTENDERED,
  SOLDTODEALERSHIP,
  SOLDTOMP,
  SOLDTORETAIL,
  SOLDVIADIRECTOFFER,
  STATUS_LIST,
} from "utils";

import { ITenderVehicleResponse, IVehicleSummary } from "types";
import { IMotorMarketBidDetails } from "types/IMotorMarket";

import { ReactComponent as ArrowDownIcon } from "assets/images/chevron-down.svg";
import { ReactComponent as ArrowUpIcon } from "assets/images/chevron-up-big.svg";

import { MotorMarketService, TenderService } from "api/client";

import { ModalTenderBidsHistory } from "../Modal/ModalTenderBidsHistory";
import "./styles.scss";

const AcquisitionDetails = ({
  data,
  flow,
}: {
  data: IVehicleSummary;
  flow?: FLOW_TYPE;
  showNotesAndTenderBid?: boolean;
}) => {
  const { userID } = useContext(AuthContext);

  const {
    acquisitions,
    owner,
    seller,
    status,
    tender_vehicle,
    auction_vehicle_id,
  } = data;

  const [showModal, setShowModal] = useState(false);
  const [isTenderModalOpen, setIsTenderModalOpen] = useState(false);
  const [isMotorMarketBidsOpen, setIsMotorMarketBidsOpen] = useState(false);

  const { data: notes, refetch: refetchNotes } = useQuery(
    [
      `fetch_note_${data.tender_vehicle?.tender_vehicle_id}_${userID}`,
      data.tender_vehicle?.tender_vehicle_id,
    ],
    TenderService.getNotes,
    {
      cacheTime: 0,
      enabled: !!data.tender_vehicle,
    }
  );

  const { data: tenderData } = useQuery<ITenderVehicleResponse, Error>(
    [
      `fetchTenderVehicle_${userID}`,
      data.tender_vehicle?.tender_vehicle_id,
      BUYING.name,
    ],
    TenderService.getTenderVehicleSummary,
    {
      cacheTime: 0,
      enabled: !!tender_vehicle?.tender_id,
    }
  );

  const { data: motorMarketBids } = useQuery<
    { data: IMotorMarketBidDetails[] },
    Error
  >(
    [`fetch_bids_${auction_vehicle_id}_${userID}`, auction_vehicle_id],
    MotorMarketService.getBids,
    {
      enabled: status.name === ACQUIRED.key && !!auction_vehicle_id,
    }
  );

  const acquisitionAmount = acquisitions?.acquisition?.amount;
  const acquiredLastName = owner?.last_name ?? "-";
  const acquiredEmail = owner?.email ?? "-";
  const acquiredPhone = owner?.phone ?? "-";
  const acquiredCompany = owner?.company_name ?? "-";
  const acquiredFrom = useMemo(() => {
    if (flow !== FLOW_TYPE.WHOLESALE_DEALERSHIP) {
      return owner && owner.first_name
        ? `${owner.first_name} ${owner.last_name}`
        : "";
    }

    return seller && seller.name
      ? seller.name
      : owner && owner.first_name
      ? `${owner.first_name} ${owner.last_name}`
      : "";
  }, [owner, seller, flow]);
  const sellerLicense = seller?.license;
  const sellerPhone = seller?.phone;

  const handleShowNotes = () => setShowModal(true);
  const handleCloseNotes = () => setShowModal(false);

  if (flow === FLOW_TYPE.WHOLESALE_DEALERSHIP) {
    return (
      <div className="disclosure__pannel--wide">
        <FieldValuation
          title="Acquisition Price"
          value={`$${formatNumber(acquisitionAmount)}`}
        />
        <FieldValuation
          title="Dealer License No."
          value={sellerLicense || "-"}
        />
        <FieldValuation title="Acquired From" value={acquiredFrom || "-"} />
        <FieldValuation title="Contact Number" value={sellerPhone || "-"} />
        {tender_vehicle?.tender_id && (
          <>
            <FieldValuation
              title="Tender Bid"
              value={
                tender_vehicle?.tender_bid_amount
                  ? `$${formatNumber(tender_vehicle?.tender_bid_amount)}`
                  : "-"
              }
              onView={
                tenderData?.data?.bid?.bid_id
                  ? () => {
                      if (tender_vehicle?.tender_bid_amount) {
                        setIsTenderModalOpen(true);
                      }
                    }
                  : undefined
              }
            />
            <FieldValuationWholeRow
              title="Notes"
              onView={handleShowNotes}
              value={notes?.data?.[0]?.comment ?? ""}
            />
          </>
        )}

        <DirectOfferBuyLog
          offerID={data?.offer_id || ""}
          offerAmount={data?.offer_amount}
        />
        {status.name === ACQUIRED.key && !!auction_vehicle_id && (
          <>
            <FieldValuation
              title="MotorMarket Bids"
              onView={() => setIsMotorMarketBidsOpen(true)}
              value={
                acquisitions.acquisition?.amount
                  ? `$${formatNumber(acquisitions.acquisition?.amount)}`
                  : "-"
              }
            />
            <ModalMotorMarketBidsHistory
              bidderID={motorMarketBids?.data[0].id || ""}
              isSeller
              isOpen={isMotorMarketBidsOpen}
              onClose={() => setIsMotorMarketBidsOpen(false)}
            />
          </>
        )}
        {status.name === ACQUIRED.key && (
          <>
            {showModal && (
              <Modal open={showModal} closeModal={handleCloseNotes} size="auto">
                <ModalAddNotes
                  notes={notes?.data || []}
                  onRefresh={refetchNotes}
                  tenderVehicleId={data.tender_vehicle?.tender_vehicle_id || ""}
                />
              </Modal>
            )}
          </>
        )}
        <Modal
          open={isTenderModalOpen}
          closeModal={() => {
            setIsTenderModalOpen(false);
          }}
          title="Bids Log"
          size="auto"
        >
          <ModalTenderBidsHistory
            bidderID={tenderData?.data?.bid?.bid_id || ""}
            isSeller
          />
        </Modal>
      </div>
    );
  }
  return (
    <div className="disclosure__pannel--wide">
      <FieldValuation
        title="Acquisition Price"
        value={`$${formatNumber(acquisitionAmount)}`}
      />
      <FieldValuation title="Email Address" value={acquiredEmail || "-"} />
      <FieldValuation title="Acquired From" value={acquiredFrom || "-"} />
      <FieldValuation title="Mobile Number" value={acquiredPhone || "-"} />
      <FieldValuation title="Last Name" value={acquiredLastName || "-"} />
      <FieldValuation title="Company Name" value={acquiredCompany || "-"} />
    </div>
  );
};

const ValuationsDetails = ({
  data,
  onRefresh,
}: {
  data: IVehicleSummary;
  onRefresh: () => void;
}) => {
  const { user } = useContext(AuthContext);
  const [showOfferModal, setShowOfferModal] = useState<boolean>(false);
  const [showInternalModal, setShowInternalModal] = useState<boolean>(false);
  const [showMpLogModal, setShowMpLogModal] = useState<boolean>(false);
  const [showWholesaleModal, setShowWholesaleModal] = useState<boolean>(false);
  const [showPricingInsightsModal, setShowPricingInsightsModal] =
    useState<boolean>(false);

  const {
    contract_id,
    valuations,
    valuation_requests,
    status: { name },
  } = data;

  const offerAmount = valuations.internal?.offer?.amount;
  const valuationAmount = valuations.internal?.valuation?.amount
    ? `$${formatNumber(valuations.internal?.valuation?.amount)}`
    : "";
  const hubOfferAmount = valuations.hub?.offer
    ? `$${formatNumber(valuations.hub?.offer?.amount)}`
    : "-";

  const handleOfferModal = () => {
    setShowOfferModal(true);
  };

  const handleInternalModal = () => {
    setShowInternalModal(true);
  };

  const handleMPLogModal = () => {
    setShowMpLogModal(true);
  };

  const handleWholesaleValuation = () => {
    setShowWholesaleModal(true);
  };

  const handleRequestDisabled = useMemo(
    () =>
      STATUS_LIST.SoldAcquisition.findIndex((item) => item.key === name) !== -1,
    [name]
  );

  if (!user) {
    return <></>;
  }

  return (
    <div className="disclosure__pannel--wide">
      <FieldValuation
        title="Offer Amount"
        value={`$${formatNumber(offerAmount)}`}
        onView={handleOfferModal}
      />
      <>
        <FieldValuation
          title="Internal Valuation (Real)"
          value={valuationAmount}
          onView={handleInternalModal}
        />
        <FieldMotorIntelValuation
          rawAmount={valuations?.hub?.offer?.amount}
          hubOfferAmount={hubOfferAmount}
          requested={valuation_requests?.hub}
          isRequesting={false}
          onOpenMPLogModal={handleMPLogModal}
          contractId={contract_id}
          vehicleName={data.vehicle.name}
          isTabView
          onRefresh={onRefresh}
          isMotorIntelDeclined={!!data.valuation_requests?.hub_declined}
        />
        <FieldValuation
          title="Wholesale Partner Valuations"
          tool={
            <ButtonLink onClick={handleWholesaleValuation}>
              <span className="!text-sm">{`Request/View >`}</span>
            </ButtonLink>
          }
        />
        <FieldValuation
          title="Pricing Insights"
          tool={
            <ButtonLink onClick={() => setShowPricingInsightsModal(true)}>
              <span className={"!text-sm"}>{`View >`}</span>
            </ButtonLink>
          }
        />
      </>
      <PricingInsightsModal
        data={data?.trade}
        isOpen={showPricingInsightsModal}
        onClose={() => setShowPricingInsightsModal(false)}
        dateCreated={data?.created_at}
      />
      <Modal
        open={showOfferModal}
        title="Offer Log"
        description="This is the offer amount sent to the person who requested the valuation."
        size="auto"
        closeModal={() => setShowOfferModal(false)}
      >
        <ModalOfferLog
          open={showOfferModal}
          valuationID={valuations.internal?.offer?.valuation_id!}
        />
      </Modal>
      <Modal
        open={showInternalModal}
        title="Internal Valuation (Real)"
        description="This is the estimated actual value of the vehicle, which is only visible to the managers of your dealership."
        size="auto"
        closeModal={() => setShowInternalModal(false)}
      >
        <ModalInternalValuation
          open={showInternalModal}
          valuation_id={valuations.internal?.valuation?.valuation_id!}
        />
      </Modal>
      <Modal
        open={showMpLogModal}
        size="auto"
        closeModal={() => setShowMpLogModal(false)}
      >
        <ModalMotorIntelLog
          open={showMpLogModal}
          valuation_id={valuations.hub?.offer?.valuation_id!}
        />
      </Modal>
      <Modal
        open={showWholesaleModal}
        title="Wholesale Partner Valuations"
        size="auto"
        closeModal={() => setShowWholesaleModal(false)}
      >
        <ModalWholesaleValuations
          contract_id={contract_id}
          open={showWholesaleModal}
          requestDisabled={handleRequestDisabled}
        />
      </Modal>
    </div>
  );
};

const SaleDetails = ({ data }: { data: IVehicleSummary }) => {
  const { user } = useContext(AuthContext);
  const { acquisitions, status, buyer } = data;
  const salesAmount = acquisitions?.sale?.amount;
  const buyerName = buyer?.name;
  const buyerLicense = buyer?.license;
  const buyerContact =
    buyer?.contact || buyer?.full_name || buyer?.company_name;
  const buyerEmail = buyer?.email;
  const buyerInvoiceEmail = buyer?.invoice_email;
  const buyerPhone = buyer?.phone;
  const buyerAddress = buyer?.address;
  const buyerABN = buyer?.abn;

  if (!user) {
    return <></>;
  }

  const {
    user: { permissions },
  } = user.data;
  return (
    <div className="disclosure__pannel--wide">
      <FieldValuation
        title="Sale Price"
        value={`$${formatNumber(salesAmount)}`}
      />
      {[
        SOLDTODEALERSHIP.key,
        SOLDTENDERED.key,
        SOLDTOMP.key,
        MOTOR_MARKET_SOLD_AUCTION.key,
        SOLDVIADIRECTOFFER.key,
      ].includes(status.name) && (
        <>
          <FieldValuation title="Contact Name" value={buyerContact || "-"} />
          <FieldValuation title="Dealership Name" value={buyerName || "-"} />
          <FieldValuation title="Contact Number" value={buyerPhone || "-"} />
          <FieldValuation
            title="Dealer License No."
            value={buyerLicense || "-"}
          />
          <FieldValuation
            title="Email Address"
            value={buyerInvoiceEmail || "-"}
          />
          <FieldValuation title="ABN" value={buyerABN || "-"} />
          <FieldValuation title="Address" value={buyerAddress || "-"} />
        </>
      )}
      {status.name === SOLDTORETAIL.key && (
        <>
          <FieldValuation title="Buyer Name" value={buyerContact || "-"} />
          <FieldValuation title="Address" value={buyerAddress || "-"} />
          <FieldValuation title="Phone Number" value={buyerPhone || "-"} />
          <FieldValuation title="Email Address" value={buyerEmail || "-"} />
          <FieldState
            variant="comment"
            style={{ gridRow: 4 }}
            name="Additional Information"
            value={acquisitions.additional_info || "-"}
          />
        </>
      )}
    </div>
  );
};

export const DealInformation = ({
  data,
  defaultOpen,
  flow,
  onRefresh,
  auctionID,
  showInMotorMarketInfo,
}: {
  data: IVehicleSummary;
  defaultOpen?: boolean;
  flow?: FLOW_TYPE;
  onRefresh: () => void;
  auctionID?: string;
  showInMotorMarketInfo?: boolean;
}) => {
  const { status } = data;
  const { user } = useContext(AuthContext);
  const { permissions } = user?.data.user || { permissions: [] as string[] };

  const [activeTab, setActiveTab] = useState<number>(0);
  const [showPanel, setShowPanel] = useState(defaultOpen);

  const handleToggleShowPanel = () => {
    setShowPanel((showPanel) => !showPanel);
  };

  const DealInformationTabs: ITabItem[] = STATUS_LIST.SoldAcquisition.map(
    (soldStatus) => soldStatus.key
  ).includes(status.name)
    ? ([
        {
          label: "ACQUISITION",
          key: "ACQUISITION",
          tabPanel: <AcquisitionDetails data={data} flow={flow} />,
        },
        {
          label: "VALUATIONS",
          key: "VALUATIONS",
          tabPanel: <ValuationsDetails data={data} onRefresh={onRefresh} />,
        },
        status.name === SOLDVIADIRECTOFFER.key
          ? {
              label: "DIRECT OFFER",
              key: "DIRECTOFFER",
              tabPanel: <DirectOfferDetails contractID={data.contract_id} />,
            }
          : undefined,
        status.name === SOLDTENDERED.key
          ? {
              label: "TENDER",
              key: "TENDER",
              tabPanel: (
                <TenderBidSection
                  tender_vehicle_id={
                    data.tender_vehicle?.tender_vehicle_id ?? ""
                  }
                />
              ),
            }
          : undefined,
        {
          label: "SALE DETAILS",
          key: "SALE DETAILS",
          tabPanel: <SaleDetails data={data} />,
        },
      ].filter((item) => item) as [])
    : status.name === ACQUIRED.key
    ? [
        {
          label: "ACQUISITION",
          key: "ACQUISITION",
          tabPanel: (
            <AcquisitionDetails data={data} flow={flow} showNotesAndTenderBid />
          ),
        },
      ]
    : ([
        {
          label: "ACQUISITION",
          key: "ACQUISITION",
          tabPanel: <AcquisitionDetails data={data} flow={flow} />,
        },
        {
          label: "VALUATIONS",
          key: "VALUATIONS",
          tabPanel: <ValuationsDetails data={data} onRefresh={onRefresh} />,
        },
      ].filter((item) => item) as []);

  return (
    <div className={`section ${showPanel && "min-h-440"}`}>
      <div
        className="section__header cursor-pointer"
        onClick={handleToggleShowPanel}
      >
        <span>Deal Information</span>
        {showPanel ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
      <div className="section__body--info">
        <Tab
          items={DealInformationTabs}
          defaultActiveTab={activeTab}
          showPanel={!!showPanel}
          onSetActiveTab={(index: number) => {
            setShowPanel(true);
            setActiveTab(index);
          }}
        />
      </div>
    </div>
  );
};
