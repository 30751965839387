import { ChangeEvent, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";

import { formVinRegoSearchTestID } from "components/app/dataTestIDs";
import {
  TextError,
  FieldText,
  Button,
  FieldDropdown,
  SvgImage,
} from "components/shared";

import { STATE_OPTIONS } from "utils";

import { IVehicle } from "types";

import { VehicleSearchService } from "api/client";

export interface VinRegoFormType {
  plate: string;
  state?: string;
}

export interface FormVinRegoSearchProps {
  isAlreadyExists?: boolean;
  onCancel?: () => void;
  onNextStep: (val: IVehicle) => void;
  dataTestID?: string;
}

export const FormVinRegoSearch = ({
  isAlreadyExists,
  onCancel,
  onNextStep,
  dataTestID,
}: FormVinRegoSearchProps) => {
  const [comments, setComments] = useState<string | null>(null);
  const [isSearching, setSearching] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      plate: "",
      state: "",
    },
  });

  const handleFindVehicle = async ({ state, plate }: VinRegoFormType) => {
    try {
      setSearching(true);
      const param = {
        plate,
        state: state || undefined,
        comments: isAlreadyExists ? comments : undefined,
        force_search: isAlreadyExists ? 1 : undefined,
      };
      const searchResult = await VehicleSearchService.searchPlate(param);

      if (searchResult) {
        onNextStep(searchResult.data as IVehicle);
        setComments(searchResult.message);
      }
    } catch (err) {
      toast.error("Failed to Find Vehicle", {
        theme: "colored",
        autoClose: 2000,
      });
    }
    setSearching(false);
  };

  const handleVinChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue("plate", e.target.value.split(" ").join("").toUpperCase());
    setIsDisabled(getValues("plate").length > 7 ? true : false);
  };

  if (isAlreadyExists) {
    return (
      <>
        <div className="flex justify-center mb-5">
          <SvgImage name="ConfirmIcon" />
        </div>
        <h3 className="vin-rego__form__title">Existing Appraisal</h3>
        <p className="text-center text-sm text-lightBlue">{comments}</p>
        <div className="mt-5 gap-2 flex justify-between">
          <Button disabled={isSearching} onClick={onCancel} variant="secondary">
            <span className="font-bold uppercase">Cancel</span>
          </Button>
          <Button
            type="submit"
            isLoading={isSearching}
            onClick={handleSubmit(handleFindVehicle)}
            dataTestID={`${dataTestID || formVinRegoSearchTestID}-submit`}
          >
            <span className="font-bold uppercase">Continue</span>
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className="vin-rego__form__row"
        data-testid={dataTestID || formVinRegoSearchTestID}
      >
        <div>
          <Controller
            render={({ field: { value } }) => (
              <FieldText
                title="VIN / Registration Plate*"
                type="text"
                value={value}
                onChange={(e) => handleVinChange(e)}
                placeholder="Enter your VIN / Registration Plate"
              />
            )}
            control={control}
            name="plate"
            rules={{ required: true }}
          />
          {errors.plate && <TextError error="VIN / REGO is required" />}
        </div>

        <div>
          <Controller
            render={({ field: { value, onChange } }) => (
              <FieldDropdown
                title="Registration State*"
                value={value}
                disabled={isDisabled}
                onChange={onChange}
                placeholder="Select State"
                options={STATE_OPTIONS}
              />
            )}
            control={control}
            name="state"
            rules={{ required: isDisabled ? false : true }}
          />
          {errors.state && <TextError error="Registration State is required" />}
        </div>
      </div>
      <div className="vin-rego__footer">
        <Button
          type="submit"
          isLoading={isSearching}
          onClick={handleSubmit(handleFindVehicle)}
          dataTestID={`${dataTestID || formVinRegoSearchTestID}-submit`}
        >
          <span className="vin-rego__footer__title">Find Vehicle</span>
        </Button>
      </div>
    </>
  );
};
