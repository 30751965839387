import { useMutation, useQuery } from "@tanstack/react-query";
import { useState, useCallback, useMemo, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  TextError,
  FieldText,
  Button,
  FieldRadio,
  FieldDropdown,
  ButtonLink,
  Accordion,
  Modal,
} from "components/shared";

import { AuthContext } from "context/AuthContext";

import {
  FeatureRoute,
  canManageDealership,
  checkIsValidNumber,
  formatNumber,
} from "utils";

import { IWholesaleDealership, IOption, IVehicleSummary } from "types";

import { AcquisitionService, ValuationService } from "api/client";

import { ConfirmSale } from "./ConfirmSale";
import { FormExternalSales } from "./FormExternalSales";
import { FormNewDealershipSales } from "./FormNewDealershipSales";
import { FormRetailSales } from "./FormRetailSales";
import "../../styles.scss";

interface SaleDetailsFormType {
  dealershipName: string;
  sale_price: string;
}

interface ModalSaleDetailsProps {
  onCloseModal: () => void;
  data: IVehicleSummary;
  contractId: string;
  dealershipType: string | undefined;
  dealershipId: string | undefined;
  onRefresh?: () => void;
}

export const SOLD_OPTIONS = [
  { value: "retailer", label: "Retail" },
  { value: "mp", label: "MotorPlatform" },
  { value: "dealership", label: "Dealership" },
];

export const ModalSaleDetails = ({
  onCloseModal,
  data,
  contractId,
  dealershipType,
  dealershipId,
  onRefresh,
}: ModalSaleDetailsProps) => {
  const { userID } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedSaleOption, setSelectedSaleOption] = useState("dealership");
  const [isNew, setIsNew] = useState(false);
  const [isNumber, setIsNumber] = useState(true);
  const [existingDealershipSelection, setExistingDealershipSelection] =
    useState<string>();
  const [openExternalSales, setOpenExternalSales] = useState(false);
  const [selectDealerhipError, setSelectDealerhipError] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [salePayload, setSalePayload] = useState<any>();
  const [openSearchDealershipForm, setOpenSearchDealershipForm] =
    useState(false);

  const {
    register,
    getValues,
    setValue,
    setError,
    handleSubmit,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<SaleDetailsFormType>({
    defaultValues: {
      sale_price: "",
      dealershipName: "",
    },
  });

  const mpVal = data.valuations.hub?.offer?.amount;
  const isWholesaleDealership = pathname.includes(
    FeatureRoute.WholesaleDealership
  );

  const { data: { data: externalDealerships } = {} } = useQuery<
    { data: IWholesaleDealership[] },
    Error
  >(
    [`fetchWholesaleValuation_${data.contract_id}_${userID}`, data.contract_id],
    ValuationService.getWholesaleValuation,
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const firstExternalDealerships = useMemo(() => {
    if (externalDealerships) {
      const valuedDealerships = externalDealerships.filter(
        (dealership) => dealership.valuation?.offer.amount
      );

      return valuedDealerships;
    }

    return [];
  }, [externalDealerships]);

  const dealershipOptions: IOption[] = useMemo(() => {
    return (
      externalDealerships?.map((dealership) => ({
        key: dealership.dealership.id,
        label: dealership.dealership.name,
      })) || []
    );
  }, [externalDealerships]);

  const { mutate, isLoading } = useMutation(
    (data: any) => AcquisitionService.sellTo(contractId, data.type, data),
    {
      onSuccess: () => {
        toast.success("Sold successfully!", {
          autoClose: 2000,
          theme: "colored",
        });
        onRefreshAndClose();
      },
      onError: () => {
        toast.error("Failed to Sell", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const { mutate: handleSellToMP, isLoading: sellingToMP } = useMutation(
    () =>
      AcquisitionService.sellTo(data.contract_id, "dealership", {
        sale_price: mpVal,
        type: "hub",
      }),
    {
      onSuccess: () => {
        onRefreshAndClose();
        toast.success("Sold successfully", {
          autoClose: 2000,
          theme: "colored",
        });
      },
      onError: () => {
        toast.error("Failed to sell", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const onRefreshAndClose = () => {
    onRefresh?.();
    setOpenConfirmModal(false);
    onCloseModal();
  };

  const handleConfirm = async (data: SaleDetailsFormType) => {
    const { sale_price, dealershipName } = data;
    if (selectedSaleOption === SOLD_OPTIONS[2].value) {
      if (!existingDealershipSelection && (!sale_price || !dealershipName)) {
        if (dealershipName === "") {
          setSelectDealerhipError("Please Select the Buyer");
        }
        if (sale_price === "") {
          setError("sale_price", {
            type: "required",
            message: "Sale Price is required",
          });
        }
        return;
      }

      const payload = {
        type: "dealership",
        sale_price: existingDealershipSelection
          ? externalDealerships?.find(
              (d) => d.dealership.id === existingDealershipSelection
            )?.valuation?.offer.amount
          : sale_price.replaceAll(",", ""),
        dealership_id: existingDealershipSelection || dealershipName,
      };
      setSalePayload(payload);
      setOpenConfirmModal(true);
    } else if (selectedSaleOption === SOLD_OPTIONS[1].value) {
      setOpenConfirmModal(true);
    }
  };

  const handleSell = () => {
    if (selectedSaleOption === SOLD_OPTIONS[2].value) {
      mutate(salePayload);
    } else {
      handleSellToMP();
    }
  };

  const handleSoldTo = useCallback((handler: string) => {
    setSelectedSaleOption(handler);
  }, []);

  const handleGoExternalSales = () => setOpenExternalSales(true);

  const handleNewCreate = useCallback((onChange: any) => {
    setIsNew(true);
    onChange("");
  }, []);

  const onChangeDealership = (val: string, onChange: any) => {
    setIsNew(false);
    onChange(val);
    setSelectDealerhipError("");
  };

  const handleSalePrice = (value: string) => {
    setValue("sale_price", formatNumber(value.replaceAll(",", "")));
    setIsNumber(checkIsValidNumber(getValues("sale_price")));
    clearErrors("sale_price");
  };

  const handleToggleSearchDealershipForm = () => {
    setOpenSearchDealershipForm((v) => !v);
    if (!openSearchDealershipForm) {
      setExistingDealershipSelection(undefined);
    }
  };

  const isSearchDealership =
    selectedSaleOption === SOLD_OPTIONS[1].value &&
    !existingDealershipSelection;
  if (selectedSaleOption === SOLD_OPTIONS[0].value) {
    return (
      <FormRetailSales
        contract_id={data.contract_id}
        onRefresh={onRefreshAndClose}
        onSoldTo={handleSoldTo}
        soldOption={selectedSaleOption}
      />
    );
  }

  if (openExternalSales) {
    return (
      <FormExternalSales
        contract_id={contractId}
        externalDealerships={externalDealerships || []}
        onRefresh={onRefreshAndClose}
        onGoBack={() => setOpenExternalSales(false)}
      />
    );
  }

  if (isNew) {
    return (
      <FormNewDealershipSales
        contractId={data.contract_id}
        dealershipId={dealershipId || ""}
        onGoBack={() => setIsNew(false)}
        onRefresh={onRefreshAndClose}
      />
    );
  }

  return (
    <div className="sale-details">
      <div className="sale-details__row">
        <div>
          <label className="sale-details__title">Sell To</label>
          <div className="sale-details__control">
            <div className="sale-details__field">
              <FieldRadio
                name={SOLD_OPTIONS[0].value}
                label={SOLD_OPTIONS[0].label}
                checked={selectedSaleOption === SOLD_OPTIONS[0].value}
                onChange={() => handleSoldTo(SOLD_OPTIONS[0].value)}
              />
            </div>
            {!canManageDealership(dealershipType) && !!mpVal && (
              <div className="sale-details__field">
                <FieldRadio
                  name={SOLD_OPTIONS[1].value}
                  label={SOLD_OPTIONS[1].label}
                  checked={selectedSaleOption === SOLD_OPTIONS[1].value}
                  onChange={() => handleSoldTo(SOLD_OPTIONS[1].value)}
                />
                <span>${formatNumber(mpVal)}</span>
              </div>
            )}
            <div className="sale-details__field">
              <FieldRadio
                name={SOLD_OPTIONS[2].value}
                label={SOLD_OPTIONS[2].label}
                checked={selectedSaleOption === SOLD_OPTIONS[2].value}
                onChange={() => handleSoldTo(SOLD_OPTIONS[2].value)}
              />
            </div>
            {selectDealerhipError && (
              <div className="flex justify-end">
                <TextError error={selectDealerhipError} />
              </div>
            )}
          </div>
          {firstExternalDealerships.length > 0 && (
            <div className="pl-6">
              {firstExternalDealerships.slice(0, 3).map((dealership, idx) => (
                <div className="sale-details__field border-none" key={idx}>
                  <FieldRadio
                    name={dealership.dealership.id}
                    label={dealership.dealership.name}
                    checked={
                      existingDealershipSelection === dealership.dealership.id
                    }
                    onChange={() => {
                      setOpenSearchDealershipForm(false);
                      setExistingDealershipSelection(dealership.dealership.id);
                      setSelectDealerhipError("");
                      clearErrors("sale_price");
                    }}
                  />
                  <span>
                    ${formatNumber(dealership.valuation?.offer.amount)}
                  </span>
                </div>
              ))}
              {firstExternalDealerships.length > 3 && (
                <div className="w-full flex justify-end">
                  <ButtonLink onClick={handleGoExternalSales}>
                    <span className="text-secondary">{"View All >"}</span>
                  </ButtonLink>
                </div>
              )}
            </div>
          )}
          <div className="w-full flex flex-col">
            <Accordion
              defaultOpen={openSearchDealershipForm}
              onToggle={handleToggleSearchDealershipForm}
              compact
              label="Search or Add Dealership"
            >
              <></>
            </Accordion>
            {openSearchDealershipForm && (
              <>
                <div className="sale-details__price mt-3">
                  <FieldText
                    title="Sale Price*"
                    type="text"
                    {...register("sale_price", {
                      required: isSearchDealership,
                    })}
                    placeholder="Enter Sale Price"
                    onChange={(e) => handleSalePrice(e.target.value)}
                  />
                  <span className="sale-details__price__unit">$</span>
                  <div>
                    {errors.sale_price && (
                      <TextError error="Sale Price is required" />
                    )}
                  </div>
                  <div>
                    {isNumber === false && (
                      <TextError error="Please enter numbers only" />
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <Controller
                    render={({ field: { value, onChange } }) => (
                      <FieldDropdown
                        title="Dealership Name*"
                        value={value}
                        onChange={(val) => onChangeDealership(val, onChange)}
                        onCreate={() => handleNewCreate(onChange)}
                        placeholder="Enter Dealership Name"
                        options={dealershipOptions}
                      />
                    )}
                    control={control}
                    name="dealershipName"
                    rules={{ required: isSearchDealership }}
                  />
                  {errors.dealershipName && (
                    <TextError error="Dealership Name is required" />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="sale-details__footer">
        <Button
          className="btn-confirm"
          type="submit"
          isLoading={sellingToMP || isLoading}
          onClick={handleSubmit(handleConfirm)}
          disabled={!isNumber}
        >
          <span>Confirm</span>
        </Button>
      </div>
      <Modal
        open={openConfirmModal}
        size="auto"
        closeModal={() => setOpenConfirmModal(false)}
      >
        <ConfirmSale
          onClose={() => setOpenConfirmModal(false)}
          onConfirm={handleSell}
        />
      </Modal>
    </div>
  );
};
