import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { flexRender } from "@tanstack/react-table";

export const DraggableTableHeader = ({
  header,
  onSort,
  isSorted,
  isDesc,
}: {
  header: any;
  onSort: (id: string) => void;
  isSorted: boolean;
  isDesc: boolean;
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } =
    useSortable({
      id: header.id,
    });

  const transformStyle = CSS.Translate.toString(transform);

  return (
    <th
      colSpan={header.colSpan}
      className="bg-gray-100 border-gray-300 px-4 py-2 text-left font-bold bg-[#EFEFEF] text-[10px] first:rounded-tl-12 last:rounded-tr-xl py-3"
    >
      <div className="flex items-center justify-between">
        <div
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          className={`inline-block cursor-grab transition-transform ${
            isDragging ? "opacity-50" : ""
          }`}
          style={{ transform: transformStyle }}
        >
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </div>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onSort(header.id);
          }}
          className="ml-2 cursor-pointer bg-transparent border-none text-gray-600"
        >
          {isSorted ? (isDesc ? "🔽" : "🔼") : "⬍"}
        </button>
      </div>
    </th>
  );
};
