import { DateTime } from "luxon";
import { toast } from "react-toastify";

import { IBidDetails } from "types";

import { FLOW_TYPE } from "./constants";
import {
  ACCEPTED,
  ACQUIRED,
  BUYNOW,
  DECLINED,
  LOSTDEAL,
  LOSTSALE,
  MOTOR_MARKET_SOLD_AUCTION,
  NEGOTIATING,
  REJECTED,
  REVOKED,
  SOLD,
  SOLDTENDERED,
  SOLDTODEALERSHIP,
  SOLDTOMP,
  SOLDTORETAIL,
  SOLDVIADIRECTOFFER,
} from "./statuses";

export const canManageDealership = (dealership_type: string | undefined) => {
  return dealership_type === "hub";
};

export const formatDateFromISO = (date: string) => {
  return DateTime.fromISO(date)
    .toFormat("d LLL yyyy - h:mm a")
    .replace(/\b(?:AM|PM)\b/g, (match) => match.toLowerCase());
};

export const formatDateToJsDate = (date: string) => {
  return DateTime.fromFormat(date, "dd LLL yyyy - hh:mm a").toJSDate();
};

export const formatNumber = (numberVar: string | number | null | undefined) => {
  if (!numberVar) return "";
  if (isNaN(+numberVar)) return `${numberVar}`;

  return Number(numberVar).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
};

/**
 *
 * @returns converts the number to support decimal places along with commas
 */
export const formatNumberWithDecimal = (
  amount: string | number | null | undefined
) => {
  if (!amount) return "";

  //check if there is a decimal
  const checkIfItHasComma = amount.toString().includes(".");

  if (checkIfItHasComma) {
    // we need to split them, 1st index for the whole and 2nd index for the decimal places
    const decimalPart = amount.toString().split(".");
    // the first index is the whole number, we need to add comma in this part
    const wholeNumber = Number(
      decimalPart[0].toString().replace(/\D/g, "")
    ).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });

    // if the decimal places is not yet entered by the user. Example 309. - we want to make sure that character '.' won't be deleted
    // limit decimal places into 2
    // remove comma in decimal places
    const decimalIndex = decimalPart[1].replace(/,/, "").substring(0, 2);

    // first index with the commas + the decimal part
    return `${wholeNumber}.${decimalIndex}`;
  }

  //we added the replace function because characters like ',' and '.' will cause this to not work
  return Number(amount.toString().replace(/\D/g, "")).toLocaleString(
    undefined,
    {
      maximumFractionDigits: 2,
    }
  );
};

export const convertToNumber = (numberVar?: string | number) => {
  if (!numberVar) return "";
  if (typeof numberVar !== "number") {
    const num = +numberVar.toString().replace(/[^0-9.]/g, "");
    return +num.toFixed(3).slice(0, -1);
  }
  return +numberVar.toFixed(3).slice(0, -1);
};

export const checkIsValidNumber = (value: string) => {
  return /^[0-9,]*$/.test(value);
};

export const getCurrentPrice = (data: any) => {
  if (data) {
    const { acquisitions, valuations, status, offer_amount } = data;
    switch (status?.name?.toLowerCase()) {
      case NEGOTIATING.key:
        return valuations?.internal?.offer?.amount;
      case ACCEPTED.key:
        return valuations?.internal?.offer?.amount;
      case ACQUIRED.key:
        return acquisitions?.acquisition?.amount;
      case DECLINED.key:
        return acquisitions?.acquisition?.amount;
      case LOSTDEAL.key:
        return valuations?.internal?.offer?.amount;
      case SOLD.key:
      case SOLDTODEALERSHIP.key:
      case SOLDTORETAIL.key:
      case SOLDTENDERED.key:
      case SOLDTOMP.key:
      case MOTOR_MARKET_SOLD_AUCTION.key:
        return acquisitions?.sale?.amount;
      case BUYNOW.key:
      case REJECTED.key:
      case REVOKED.key:
      case LOSTSALE.key:
        return offer_amount;
      case SOLDVIADIRECTOFFER.key:
        return acquisitions?.sale?.amount;
      default:
        return null;
    }
  }
  return null;
};

export const showSuccessToast = (message: string) => {
  toast.success(message, {
    autoClose: 2000,
    theme: "colored",
  });
};

export const showErrorToast = (message: string) => {
  toast.error(message, {
    autoClose: 2000,
    theme: "colored",
  });
};

export const getHighestBid = (bids: IBidDetails[] | undefined) => {
  if (!bids || bids.length === 0) return null;

  let highestBid: IBidDetails = bids[0];
  bids.forEach((bid) => {
    if (bid.amount > highestBid.amount) {
      highestBid = bid;
    }
  });

  return highestBid;
};

export const getVehicleInformationRoute = (
  flow: FLOW_TYPE
): { title: string; path: string } => {
  return {
    title:
      flow === FLOW_TYPE.RETAIL_DEALERSHIP
        ? "Retail Dealership"
        : "Wholesale Dealership",
    path:
      flow === FLOW_TYPE.RETAIL_DEALERSHIP
        ? "/retail-dealership"
        : "/wholesale-dealership",
  };
};

export const setDocumentScrollPosition = (scrollPosition: string) =>
  setTimeout(() => {
    document
      .getElementsByClassName("dashboard")[0]
      .scrollTo({ behavior: "smooth", top: parseInt(scrollPosition) });
  }, 500);

export const createEllipsisText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};

export const formatToNumber = (amount?: string): number => {
  return amount ? parseFloat(amount.replace(/[$,]/g, "")) || 0 : 0;
};

export const formatAmount = (
  amount: string | number | null | undefined,
  prefix = "$"
): string => {
  if (amount == null || amount === "") return "";

  const parsedAmount =
    typeof amount === "string" ? formatToNumber(amount) : amount;

  return isNaN(parsedAmount)
    ? String(amount)
    : `${prefix}${parsedAmount.toLocaleString()}`;
};
