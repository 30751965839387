import {
  AddCommentPayload,
  ICustomerDetail,
  IImage,
  IImageIDs,
  IPPSR,
  IQueryKeyProps,
  IVehicleComments,
  IVehicleDetail,
  IVehicleOptions,
  IVehicleResponse,
  IVehicleSummary,
} from "types";
import { IVinSpecifications } from "types/IVinSpecifications";

import { api, url } from "api";

export const VehicleService = {
  updateVehicle: (payload: any, contract_id: string) =>
    api.patch(`${url.vehicles}/${contract_id}`, payload),

  removeVehicle: (contract_id: string) =>
    api.delete(`${url.vehicles}/${contract_id}`, {}),

  getImages: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.vehicles}/${queryKey[1]}/images`) as Promise<{
      data: IImage[];
    }>,

  uploadMultiImage: (payload: any, contract_id: string) =>
    api.post(
      `${url.vehicles}/${contract_id}/multiple-images`,
      payload,
      "form-data"
    ),

  uploadImage: (payload: any, contract_id: string) =>
    api.post(
      `${url.vehicles}/${contract_id}/images`,
      payload,
      "form-data"
    ) as Promise<{ data: IImage }>,

  sortImages: (payload: IImageIDs, contractID: string) =>
    api.post(`${url.vehicles}/${contractID}/images/sort`, payload),

  removeImage: (payload: any, contract_id: string) =>
    api.delete(`${url.vehicles}/${contract_id}/images`, payload),

  saveAnswers: (payload: any, contract_id: string) =>
    api.post(`${url.vehicles}/${contract_id}/assessments`, payload),

  addOwner: (payload: any, contract_id: string) =>
    api.post(`${url.vehicles}/${contract_id}/owner`, payload) as Promise<{
      data: ICustomerDetail;
    }>,

  notifyValuers: (contract_id: string) =>
    api.post(`${url.vehicles}/${contract_id}/notifications`, {}),

  getVehicleVinSpecifications: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.vehicles}/${queryKey[1]}/specifications`) as Promise<{
      data: IVinSpecifications;
    }>,

  getVehicleOptions: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.vehicles}/${queryKey[1]}/options`) as Promise<{
      data: IVehicleOptions;
    }>,

  getVehicleComments: ({ queryKey }: IQueryKeyProps) =>
    api.get(`/comments/${queryKey[1]}/contract`, {
      dealership_id: queryKey[2],
    }) as Promise<IVehicleComments>,

  addComment: (payload: AddCommentPayload, contract_id: string) =>
    api.post(`/comments/${contract_id}`, payload),

  searchPPSR: (contractID: string, refetch: boolean) =>
    api.post(`${url.ppsr}/${contractID}/search`, {
      refetch,
    }) as Promise<{
      data: IPPSR;
    }>,

  fetchPPSRCertificate: (contractID: string) =>
    api.post(`${url.ppsr}/${contractID}/certificate`, {}) as Promise<string>,

  fetchGlobalSearch: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.search}/vehicles`, {
      search: queryKey[1],
      page: queryKey[2],
      dealership_id: queryKey[3],
    }) as Promise<IVehicleResponse>,

  fetchGlobalSearchDetails: ({ queryKey }: IQueryKeyProps) =>
    api.get(`${url.search}/vehicles/${queryKey[1]}`, queryKey[2]) as Promise<{
      data: IVehicleSummary;
    }>,
};
