import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { LoadVehicleSummary, VehicleInformation } from "components/app";
import { ImageSlider } from "components/ImageSlider/ImageSlider";
import { NoPermissionMessage } from "components/NoPermissionMessage";
import { Banner, Breadcrumbs, ButtonLink } from "components/shared";
import { Timer } from "components/Timer";
import { VehicleComments } from "components/VehicleNotes";

import { AuthContext } from "context/AuthContext";

import {
  convertToDate,
  ERROR_MESSAGE,
  formatDateToJsDate,
  formatNumber,
  MOTOR_MARKET_ENDED,
  MOTOR_MARKET_LOST,
  MOTOR_MARKET_WON,
  VEHICLE_SUMMARY_TITLE,
} from "utils";
import { getPageTitle, SECTIONS } from "utils/pageSections";
import { PermissionAction, PermissionCategory } from "utils/userPermissions";

import { IMotorMarket } from "types/IMotorMarket";

import { AcquisitionService } from "api/client";

import { BuyerBids } from "./BuyerBids";
import { DealershipInformation } from "./DealershipInformation";
import { Watchlist } from "./Watchlist";

interface StateProps {
  selectedVehicle: IMotorMarket;
}

interface BuyerSummaryProps {
  vehicle?: IMotorMarket;
  isSearch?: boolean;
}

export const BuyerSummary = ({ vehicle, isSearch }: BuyerSummaryProps) => {
  const { userID, hasPermission } = useContext(AuthContext);

  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const selectedVehicle = (state as StateProps)?.selectedVehicle || vehicle;

  const hasAuctionEnded = [
    MOTOR_MARKET_ENDED.key,
    MOTOR_MARKET_WON.key,
    MOTOR_MARKET_LOST.key,
  ].includes(selectedVehicle.status.name);

  const contractID = params.id;

  const canView = hasPermission(
    PermissionCategory.MOTOR_MARKET_BUYING,
    PermissionAction.VIEW
  );

  const {
    data: vehicleSummary,
    isLoading: isLoadingVehicleSummary,
    isError: isErrorVehicleSummary,
    isSuccess: isSuccessVehicleSummary,
    refetch: vehicleSummaryRefetch,
  } = useQuery(
    [
      `fetch_detailed_summary_data_${userID}`,
      contractID,
      {
        with: "dealership,owner,seller,assessments,valuations,images,buyer,comment,expenses,bids",
      },
    ],
    AcquisitionService.getAcquisitionSummary,
    {
      cacheTime: 0,
      enabled: canView,
    }
  );

  const endsAt = isSearch
    ? vehicleSummary?.data?.auction?.ends_at
    : selectedVehicle.ends_at;

  const renderSummary = () => {
    if (!canView) {
      return (
        <NoPermissionMessage
          viewPermissionCategory={PermissionCategory.MOTOR_MARKET_BUYING}
        />
      );
    }

    if (isLoadingVehicleSummary) {
      return <LoadVehicleSummary />;
    }

    if (isErrorVehicleSummary) return <div>{ERROR_MESSAGE}</div>;

    if (isSuccessVehicleSummary && vehicleSummary?.data) {
      return (
        <>
          <div className="dashboard__content--left">
            <VehicleInformation
              data={vehicleSummary.data}
              refetch={vehicleSummaryRefetch}
              defaultOpen
              isLocked
            />
            <DealershipInformation data={vehicleSummary.data} />
          </div>
          <div className="dashboard__content--right">
            {(selectedVehicle.status.name === MOTOR_MARKET_LOST.key ||
              selectedVehicle.status.name === MOTOR_MARKET_WON.key) &&
              !vehicleSummary?.data?.images?.length && (
                <Banner
                  name={selectedVehicle.status.name}
                  label={selectedVehicle.status.label}
                />
              )}
            {selectedVehicle.status.name === MOTOR_MARKET_WON.key && (
              <div className="py-11 pl-5 rounded-lg bg-[#F6F7F9] shadow-md flex gap-5 flex-col items-start">
                <div className="font-medium text-xl">Sale Options</div>
                <p className="text-sm text-label">
                  If you would like to sell this vehicle:
                </p>
                <ButtonLink
                  onClick={() =>
                    navigate(
                      `/wholesale-dealership/stocklist/${vehicleSummary.data?.acquisitions?.new_contract_id}/undefined/summary`
                    )
                  }
                >
                  <span className={"text-secondary underline"}>
                    View Acquired Vehicle In Your Stocklist
                  </span>
                </ButtonLink>
              </div>
            )}

            {!hasAuctionEnded && (
              <Watchlist
                selectedVehicle={selectedVehicle}
                refetch={vehicleSummaryRefetch}
                watchlistID={vehicleSummary.data?.auction_watchlist_id}
                auctionVehicleID={vehicleSummary?.data?.auction_vehicle_id}
              />
            )}
            <div className="border rounded-xl border-[#D6D6D6]">
              {endsAt && (
                <div className="m-5">
                  <Timer
                    targetDate={formatDateToJsDate(endsAt)}
                    isSummaryView
                    isBuyerView
                  />
                </div>
              )}
              <BuyerBids
                vehicleAuctionID={
                  selectedVehicle?.auction_vehicle_id ||
                  vehicleSummary?.data?.auction_vehicle_id
                }
                hasAuctionEnded={hasAuctionEnded}
              />
            </div>
            <VehicleComments
              contractID={vehicleSummary.data.contract_id}
              canComment={canView}
            />
          </div>
        </>
      );
    }

    return <></>;
  };

  const expiry =
    vehicleSummary?.data.vehicle.compliance.registration_expiry_date;
  const expiryDate = typeof expiry === "string" ? expiry : expiry?.value;

  return (
    <>
      <Helmet>
        <title>{getPageTitle("Stocklist", SECTIONS.MOTORMARKET)}</title>
      </Helmet>
      <div className="dashboard__container">
        <div className="dashboard__header">
          <Breadcrumbs
            paths={[
              {
                title: "MotorMarket",
                route: "/motor-market",
              },
              {
                title: "Buying",
                route: "/motor-market/buying",
              },
              {
                title: VEHICLE_SUMMARY_TITLE,
                route: `/motor-market/buying/${contractID}/summary`,
              },
            ]}
          />
        </div>
        <div className="flex w-full py-5 gap-10 items-center">
          <div className="flex flex-col flex-1 overflow-auto min-w-840">
            <p className="text-2xl text-txt font-semibold mt-5">
              {vehicleSummary?.data?.vehicle?.name}
            </p>
            <div className="flex items-center text-sm text-label gap-3 mt-1/2">
              {(selectedVehicle.vehicle.compliance.registration_plate ||
                selectedVehicle.vehicle.compliance.registration_state) && (
                <>
                  <span>
                    {selectedVehicle.vehicle.compliance.registration_plate}
                  </span>
                  <span>
                    {selectedVehicle.vehicle.compliance.registration_state}
                  </span>
                  <span>|</span>
                </>
              )}
              <span>
                Km:&nbsp;
                {formatNumber(vehicleSummary?.data.vehicle?.odometer || 0) ||
                  "-"}
              </span>
              <span>|</span>
              Colour:&nbsp;
              {vehicleSummary?.data.vehicle.specification.colour || "-"}
              <span>|</span>
              <span>Rego Expiry:&nbsp;{convertToDate(expiryDate)}</span>
            </div>
          </div>
          <div className="flex flex-col gap-10 min-w-430">
            {(selectedVehicle.status.name === MOTOR_MARKET_LOST.key ||
              selectedVehicle.status.name === MOTOR_MARKET_WON.key) &&
              !!vehicleSummary?.data?.images?.length && (
                <div className="">
                  <Banner
                    name={selectedVehicle.status.name}
                    label={selectedVehicle.status.label}
                  />
                </div>
              )}
          </div>
        </div>

        {!!vehicleSummary?.data?.images?.length && (
          <ImageSlider images={vehicleSummary?.data?.images || []} />
        )}
        <div className="dashboard__content">{renderSummary()}</div>
      </div>
    </>
  );
};
