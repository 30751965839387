import { useMutation } from "@tanstack/react-query";

import { LoadingSpinner } from "components/Loading/LoadingSpinner";
import { SvgImage } from "components/shared";

import { showErrorToast } from "utils";

import { PPSRService } from "api/client";

type PPSRCertificateProps = {
  ppsrID?: string;
};

export const DownloadCertificate = ({ ppsrID }: PPSRCertificateProps) => {
  const { mutate: fetchCertificate, isLoading: isLoadingFetchCertificate } =
    useMutation(() => PPSRService.fetchCertificate(ppsrID || ""), {
      onSuccess: (data) => {
        if (data) {
          const newWindow = window.open(data, "_blank", "noopener,noreferrer");
          if (newWindow) newWindow.opener = null;
        }
      },
      onError: () => {
        showErrorToast("Failed to Download. Please try again.");
      },
    });

  if (!ppsrID) return null;

  if (isLoadingFetchCertificate) {
    return (
      <span className="h-3 absolute right-0">
        <LoadingSpinner size={10} />
      </span>
    );
  }

  return (
    <span onClick={() => fetchCertificate()}>
      <SvgImage name="DownloadIcon" />
    </span>
  );
};
