import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { LoadVehicleSummary } from "components/app";
import {
  Breadcrumbs,
  Button,
  ButtonLink,
  FieldCheckbox,
  FieldText,
  Prompt,
  SvgImage,
} from "components/shared";

import { AuthContext } from "context/AuthContext";
import { SearchContext } from "context/SearchContext";

import {
  ERROR_MESSAGE,
  FLOW_TYPE,
  VEHICLE_ROUTES,
  VEHICLE_SUMMARY_TITLE,
  getVehicleInformationRoute,
} from "utils";

import { IVehicleOptions, IVehicleSummary } from "types";

import { DealershipInspectionService, VehicleService } from "api/client";

interface FeaturesProps {
  flow: FLOW_TYPE;
  route: VEHICLE_ROUTES;
}

interface FeaturesFormProps {
  tyre_front?: string;
  tyre_rear?: string;
}

export const Features = ({ flow, route }: FeaturesProps) => {
  const queryClient = useQueryClient();
  const { userID } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const { setIsUpdated } = useContext(SearchContext);

  const { title, path } = getVehicleInformationRoute(flow);
  const isValuedRoute = route === VEHICLE_ROUTES.Unvalued;

  const [isCommentsLoaded, setIsCommentsLoaded] = useState(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [newOption, setNewOption] = useState<string>("");
  const [isDirty, setIsDirty] = useState(false);

  const {
    control,
    handleSubmit,
    getFieldState,
    formState: { isDirty: isFormDirty },
    reset,
    register,
  } = useForm<FeaturesFormProps>();

  const [searchTerm, setSearchTerm] = useState("");
  const [checkedOptions, setCheckedOptions] = useState<Array<string>>([]);
  const [addedOptions, setAddedOptions] = useState<Array<string>>([]);

  const {
    data: vehicleOptions,
    isLoading: isLoadingVehicleOptions,
    isError: isErrorVehicleOptions,
    isSuccess: isSuccessVehicleOptions,
  } = useQuery<{ data: IVehicleOptions }, Error>(
    [`fetchVehicleOptions_${userID}`, params.id],
    VehicleService.getVehicleOptions
  );

  const { data: dealershipInspectionData } = useQuery<
    { data: IVehicleSummary },
    Error
  >(
    [`fetchDealershipInspection_${userID}`, params.id],
    DealershipInspectionService.getDealershipSummary,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        queryClient.invalidateQueries([`retail-summary-${params.id}`]);
        const stringComments =
          data?.data?.assessments?.[0]?.questionnaire?.[0]?.answers?.comments;
        const parsedComments =
          stringComments &&
          typeof stringComments === "string" &&
          JSON.parse(stringComments as unknown as string);

        setCheckedOptions(parsedComments?.options);
      },
    }
  );

  const { mutate: onSaveOptions, isLoading } = useMutation(
    (data: {
      answers: {
        question_slug: string | undefined;
        options: string[];
        comments: string;
      }[];
      type: string;
    }) => VehicleService.saveAnswers(data, params.id || ""),
    {
      onSuccess: () => {
        toast.success("Saved Successfully", {
          autoClose: 2000,
          theme: "colored",
        });
        setIsDirty(false);
        setIsUpdated(params.id || "", true);
        navigate(-1);
        reset({}, { keepDirty: false, keepValues: true });
      },
      onError: () => {
        toast.error("Failed to Save", {
          autoClose: 2000,
          theme: "colored",
        });
      },
    }
  );

  const stringComments =
    dealershipInspectionData?.data?.assessments?.[0]?.questionnaire?.[0]
      ?.answers?.comments;
  const parsedComments =
    stringComments &&
    typeof stringComments === "string" &&
    JSON.parse(stringComments as unknown as string);

  const vehicleOptionalItems = vehicleOptions?.data?.equipments?.optional;
  const wheels = vehicleOptions?.data?.equipments?.wheels;

  useEffect(() => {
    if (parsedComments?.options && !isCommentsLoaded) {
      setCheckedOptions(parsedComments?.options);
      setIsCommentsLoaded(true);
    }
  }, [parsedComments, isCommentsLoaded]);

  const combinedOptions = useMemo(() => {
    const filteredComments = parsedComments?.options?.filter(
      (c: string) => !vehicleOptionalItems?.includes(c)
    );

    const filteredAddedOptions = addedOptions?.filter(
      (c: string) => !filteredComments?.includes(c)
    );

    return [
      ...(vehicleOptionalItems || []),
      ...(filteredComments || []),
      ...(filteredAddedOptions || []),
    ];
  }, [vehicleOptionalItems, parsedComments, addedOptions]);

  const filteredData = useMemo(() => {
    const standardFeatures = vehicleOptions?.data?.equipments?.standard;
    const options = combinedOptions.filter((item) => typeof item === "string");

    if (searchTerm === "") {
      return {
        standardFeatures,
        options,
        tyre_front: true,
        tyre_rear: true,
      };
    } else {
      return {
        standardFeatures: standardFeatures?.filter((feature) =>
          feature?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        options: options?.filter((item) =>
          item?.toLowerCase().includes(searchTerm.toLowerCase())
        ),
        tyre_front: "front wheel".includes(searchTerm.toLowerCase()),
        tyre_rear: "rear wheel".includes(searchTerm.toLowerCase()),
      };
    }
  }, [vehicleOptions?.data?.equipments?.standard, searchTerm, combinedOptions]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const onSave = (data: FeaturesFormProps) => {
    const filteredComments = parsedComments?.options?.filter((c: string) =>
      checkedOptions?.includes(c)
    );

    const combinedOptions = [
      ...new Set([...(checkedOptions || []), ...(filteredComments || [])]),
    ];

    const answersPayload = [
      {
        question_slug: "spec-and-options",
        options: [],
        comments: JSON.stringify({
          tyre_front: getFieldState("tyre_front").isDirty
            ? data.tyre_front
            : parsedComments?.["tyre_front"] || wheels?.tyre_front,
          tyre_rear: getFieldState("tyre_rear").isDirty
            ? data.tyre_rear
            : parsedComments?.["tyre_rear"] || wheels?.tyre_rear,
          options: combinedOptions,
        }),
      },
    ];

    const payload = {
      answers: answersPayload,
      type: "conditions",
    };
    onSaveOptions(payload);
  };

  const handleAddOption = useCallback(() => {
    setIsNew(true);
  }, []);

  const handleNewOption = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setNewOption(e.target.value);
  };
  const handleConfirm = () => {
    if (newOption) {
      setAddedOptions([...(addedOptions || []), newOption]);
      setCheckedOptions([...(checkedOptions || []), newOption]);
      setIsNew(false);
      setNewOption("");
      setIsDirty(true);
    }
  };
  const handleCancel = () => {
    setIsNew(false);
    setNewOption("");
  };

  const onCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) => {
    setIsDirty(true);
    if (e.target.checked) {
      setCheckedOptions([...(checkedOptions || []), name]);
    } else {
      setCheckedOptions(checkedOptions.filter((option) => option !== name));
    }
  };

  const renderItems = () => {
    if (isLoadingVehicleOptions) {
      return <LoadVehicleSummary />;
    }

    if (isErrorVehicleOptions) {
      return <div className="text-sm text-center">{ERROR_MESSAGE}</div>;
    }

    if (isSuccessVehicleOptions) {
      return (
        <div className="features w-full">
          <div className="grid grid-cols-3 gap-4">
            <div>
              <div className="relative">
                <input
                  className="h-9 w-full rounded-4 border-solid border-grey border pl-10 focus:outline-none mb-7"
                  placeholder="Search"
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <span className="absolute top-2 left-3">
                  <SvgImage name="SearchIcon" width={20} height={20} />
                </span>
              </div>
              <div className="mb-7">
                <div className="font-semibold text-sm mb-3">Wheels</div>
                {filteredData.tyre_front && (
                  <div className="mb-3">
                    <Controller
                      control={control}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <FieldText
                          title="Front Tyre"
                          type="text"
                          {...register("tyre_front")}
                          placeholder="Enter Front Wheel Size"
                          onChange={onChange}
                          value={
                            fieldState.isDirty
                              ? value
                              : parsedComments?.["tyre_front"] ||
                                wheels?.tyre_front
                          }
                        />
                      )}
                      name="tyre_front"
                    />
                  </div>
                )}
                {filteredData.tyre_rear && (
                  <div className="mb-3">
                    <Controller
                      control={control}
                      render={({ field: { onChange, value }, fieldState }) => (
                        <FieldText
                          title="Rear Tyre"
                          type="text"
                          {...register("tyre_rear")}
                          placeholder="Enter Rear Wheel Size"
                          onChange={onChange}
                          value={
                            fieldState.isDirty
                              ? value
                              : parsedComments?.["tyre_rear"] ||
                                wheels?.tyre_rear
                          }
                        />
                      )}
                      name="tyre_rear"
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="font-semibold text-sm mb-3">Options</div>
                {filteredData?.options?.map(
                  (option: string) =>
                    option && (
                      <FieldCheckbox
                        key={option}
                        checked={checkedOptions?.includes(option)}
                        label={option}
                        onChange={(e) => onCheckboxChange(e, option)}
                      />
                    )
                )}
                <div>
                  {isNew && (
                    <div className="lex pr-6 !w-full !flex flex-col my-3">
                      <FieldText
                        type="text"
                        value={newOption}
                        autoFocus
                        onChange={handleNewOption}
                      />

                      <div className="option__new__tools mt-3">
                        <ButtonLink onClick={handleConfirm}>
                          <div className={"option__add"}>
                            <SvgImage name={"AddConfirmIcon"} />
                            <span className="option__add__title mr-4">
                              CONFIRM
                            </span>
                          </div>
                        </ButtonLink>
                        <ButtonLink onClick={handleCancel}>
                          <div className={"option__add"}>
                            <SvgImage name={"AddCancelIcon"} />
                            <span className="option__add__title">CANCEL</span>
                          </div>
                        </ButtonLink>
                      </div>
                    </div>
                  )}
                  <ButtonLink disabled={!!isNew} onClick={handleAddOption}>
                    <div
                      className={
                        isNew ? "option__add--disabled" : "option__add"
                      }
                    >
                      <SvgImage name={isNew ? "AddDisableIcon" : "AddIcon"} />
                      <span className="option__add__title">Add more</span>
                    </div>
                  </ButtonLink>
                </div>
              </div>
            </div>
            <div className="mt-16 col-span-2">
              <div className="font-semibold text-sm mb-3">
                Standard Features
              </div>
              <div className="flex flex-wrap overflow-y-auto">
                {filteredData?.standardFeatures?.map((feature) => (
                  <div
                    className="pb-2 flex-[50%] text-sm font-normal"
                    key={feature}
                  >
                    {feature}
                  </div>
                ))}
                {!filteredData?.standardFeatures && (
                  <div className="text-lightGrey text-sm">
                    No standard features.
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="features__tool">
            <Button
              disabled={!isDirty && !isFormDirty}
              type="submit"
              isLoading={isLoading}
              onClick={handleSubmit(onSave)}
            >
              <span className="features__tool__txt">Save</span>
            </Button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="dashboard__container">
      <div className="dashboard__header">
        <Breadcrumbs
          paths={[
            {
              title: title,
              route: path,
            },
            {
              title: route,
              route: isValuedRoute ? path : `${path}/${route?.toLowerCase()}`,
            },
            {
              title: VEHICLE_SUMMARY_TITLE,
              route: isValuedRoute
                ? `${path}/valuation/${params.id}/summary`
                : `${path}/${route?.toLowerCase()}/${params.id}/summary`,
            },
            {
              title: "Vehicle Information",
              route: isValuedRoute
                ? `${path}/valuation/${params.id}/summary`
                : `${path}/${route?.toLowerCase()}/${params.id}/summary`,
            },
            {
              title: "Features",
              route: isValuedRoute
                ? `${path}/${params.id}/features`
                : `${path}/${route?.toLowerCase()}/${params.id}/features`,
            },
          ]}
        />
        <ButtonLink onClick={handleGoBack}>
          <SvgImage name="LeftRoundedIcon" />
        </ButtonLink>
      </div>
      <div className="dashboard__content">
        <div className="features w-full">{renderItems()}</div>
      </div>
      <Prompt isDirty={isDirty || isFormDirty} />
    </div>
  );
};
