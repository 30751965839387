import { FieldState } from "components/app";

import { IVehicleSummary } from "types";

import "./styles.scss";

export const DealershipInformation = ({ data }: { data: IVehicleSummary }) => {
  const { license, name, phone, contact } =
    data.requester?.dealership || data.seller || {};

  return (
    <div className="section">
      <div className="section__header">
        <span>Dealership Information</span>
      </div>
      <div className="disclosure__pannel--wide">
        <FieldState name="Dealership Name" value={name} />
        <FieldState name="Dealer License No." value={license} />
        <FieldState
          name="Contact Name"
          value={data?.requester?.user?.name || contact}
        />
        <FieldState name="Contact Number" value={phone} />
      </div>
    </div>
  );
};
