import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useState, forwardRef, useMemo, Ref } from "react";

import { SvgImage } from "components/shared/Image";

import { IOption } from "types";

import { ComboboxOption } from "./ComboboxOption";

import "./styles.scss";

export interface CustomSelectDropdownProps {
  title?: string;
  placeholder?: string;
  isFilter?: boolean;
  disabled?: boolean;
  options: IOption[];
  value: string;
  onChange: (val: string) => void;
  onCreate?: () => void;
  prependOptions?: IOption[];
  className?: string;
  dropdownGreyBG?: boolean;
}

export const CustomSelectDropdown = forwardRef(
  (
    {
      title,
      placeholder,
      isFilter = true,
      disabled = false,
      options,
      prependOptions,
      onChange,
      value,
      className,
      dropdownGreyBG,
    }: CustomSelectDropdownProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const [query, setQuery] = useState("");

    const filteredOptions = useMemo(() => {
      return query === ""
        ? options
        : options.filter((option) => {
            return option.label
              .toString()
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, ""));
          });
    }, [options, query]);

    const handleDisplayValue = (values: string) => {
      const selectedLabels = options
        .filter((item) => values.includes(item.key as string))
        .map((item) => item.label);
      return `${selectedLabels?.[0] || ""}`;
    };

    const handleMainInput = (e: { target: { value: string } }) => {
      setQuery(e.target.value);
    };

    const handleOptionSelect = (selectedKey: string) => {
      onChange(selectedKey);
    };

    const renderOptions = (options: IOption[]) => {
      return options.map((option: IOption) => {
        return (
          <ComboboxOption
            option={option}
            value={value}
            dropdownGreyBG={dropdownGreyBG}
            parent={option.parent}
            key={option.key}
            noIndent={option.noIndent}
          />
        );
      });
    };

    return (
      <div className={disabled ? "dropdown--disabled" : "dropdown"}>
        {title && <label className="dropdown-label">{title}</label>}
        <Combobox
          value={value}
          onChange={handleOptionSelect}
          disabled={disabled}
        >
          <div className="wrapper">
            <Combobox.Button className="input-container">
              <Combobox.Input
                ref={ref}
                className={classNames("dropdown__input", null, className)}
                placeholder={placeholder}
                displayValue={() => handleDisplayValue(value)}
                readOnly
              />
              <div className="dropdown__input__icon">
                <SvgImage name="SelectDown" />
              </div>
            </Combobox.Button>

            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options
                className={classNames("dropdown__options", {
                  "!bg-inputbg": dropdownGreyBG,
                })}
              >
                {Array.isArray(prependOptions) && renderOptions(prependOptions)}
                {isFilter && (
                  <div className="dropdown__search">
                    <span className="dropdown__search__icon">
                      <SvgImage name="ManualSearchIcon" />
                    </span>
                    <input
                      className={classNames("dropdown__search__input", {
                        "bg-transparent": dropdownGreyBG,
                      })}
                      placeholder="Searching..."
                      onChange={(event: { target: { value: string } }) =>
                        setQuery(event.target.value)
                      }
                    />
                  </div>
                )}
                {filteredOptions.length === 0 && query !== "" ? (
                  <div className="dropdown__options__nothing">
                    Nothing found.
                  </div>
                ) : (
                  renderOptions(filteredOptions)
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    );
  }
);
