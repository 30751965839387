import { ChildrenDealership, IAuth } from "types";

export const dealership = {
  getIdWithChildren(
    dealership: Required<IAuth["data"]>["dealership"],
    targetId: string
  ) {
    if (dealership.id === targetId) {
      return [targetId, ...this.deepMapIds(dealership.children || {})].join(
        ","
      );
    }

    const foundChild = Object.values(dealership.children || {})?.find(
      (item) => item.id === targetId
    );

    if (!foundChild) {
      return targetId;
    }

    return [targetId, ...this.deepMapIds(foundChild.children || {})].join(",");
  },

  deepMapIds(dealerships: Record<string, ChildrenDealership>): string[] {
    return Object.values(dealerships || {})
      .map((item) =>
        !Array.isArray(item.children)
          ? [item.id, ...this.deepMapIds(item.children || {})]
          : item.id
      )
      .flat();
  },
};
