import { useEffect, useRef } from "react";

export function useEffectMounted(
  effect: React.EffectCallback,
  deps?: React.DependencyList | undefined
) {
  const mountedRef = useRef(false);

  useEffect(() => {
    if (mountedRef.current) {
      return effect();
    }
  }, deps);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
}
