import classNames from "classnames";
import { CSSProperties } from "react";

import { fieldStateTestID } from "components/app/dataTestIDs";

import "../styles.scss";

export interface FieldStateProps {
  name: string;
  value: any;
  variant?: "primary" | "error" | "comment" | "secondary";
  editable?: boolean;
  isFocused?: boolean;
  style?: CSSProperties;
  onChange?: (val: any) => void;
  onBlur?: () => void;
  dataTestID?: string;
  allowNameWrapping?: boolean;
  capitalize?: boolean;
}

export const FieldState = ({
  name,
  value,
  variant = "primary",
  editable = false,
  isFocused = false,
  style,
  onChange,
  onBlur,
  dataTestID,
  allowNameWrapping,
  capitalize,
}: FieldStateProps) => {
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && onBlur && value) {
      onBlur();
    }
  };

  return (
    <div
      className={
        !value && variant === "error"
          ? "state--error"
          : variant === "comment"
          ? "state--comment"
          : variant === "secondary"
          ? "state--secondary"
          : "state"
      }
      style={style}
      data-testid={dataTestID || fieldStateTestID}
    >
      <span
        className={classNames("state__name", {
          "w-28": !!allowNameWrapping,
        })}
      >
        {name}
      </span>
      {editable ? (
        <input
          className={value ? "state__field" : "state__field--warning"}
          value={value}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          onBlur={onBlur}
          autoFocus={!!isFocused}
          data-testid={`${dataTestID || fieldStateTestID}-input`}
        />
      ) : (
        value !== "none" && (
          <span
            className={classNames("state_value", {
              capitalize: capitalize,
            })}
          >
            {!value ? "-" : value}
          </span>
        )
      )}
    </div>
  );
};
